// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { getProfileById, getUserApi } from './apis';
import './App.css';
import MainRouter from './MainRouter/MainRouter';
import { AppContext } from './appContext';
import { useNavigate } from 'react-router-dom';
import { logoutOnJwtExpire } from './utils/helperFunctions';
import OneSignal from 'react-onesignal';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token')); // Assuming you stored the token in localStorage
  const [tokenExpiration, setTokenExpiration] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [chatCount, setChatCount] = useState(null);
  const navigate = useNavigate();
  console.log('app js', userDetails);

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setLoggedInUser(null);
    navigate('/sign-in');
    setUserDetails(null);
  };
  useEffect(()=>{
    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID ,allowLocalhostAsSecureOrigin: true, }).then(() => {
      OneSignal.Slidedown.promptPush();
      // do other stuff
    });
  },[]);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  const getUserDetails = async () => {
    try {
      const res = await getProfileById(userDetails?._id);
      if (res.status === 200) {
        setUserDetails(res.data.Profile);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUserProfile = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setLoggedInUser( res.data?.Profile );
        setUserDetails(res.data?.Profile);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
      else if(error?.response?.data?.error==='User does not exist'){
        logoutOnJwtExpire();
        navigate('/sign-in');
      }
    }
  };

  useEffect(() => {
    if (Boolean(localStorage.getItem('token'))) {
      setIsLoggedIn(true);
    }

    clearCacheData();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserProfile();
      setToken(localStorage.getItem('token'));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const tokenExpirationTime = new Date(tokenData.exp * 1000);
      setTokenExpiration(tokenExpirationTime);
      const timer = setInterval(() => {
        const currentTime = new Date();
        if (currentTime > tokenExpirationTime) {
          clearInterval(timer);
          localStorage.removeItem('token');
          setIsLoggedIn(false);
          setLoggedInUser(null);
          navigate('/sign-in');
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [token]);

  return (
    // <BrowserRouter>
    <AppContext.Provider
      value={{
        state: {
          isLoggedIn,
          loggedInUser,
        },
        userDetails,
        setUserDetails,
        loggedInUser,
        setIsLoggedIn,
        fetchUserProfile,
        logout,
        getUserDetails,
        chatCount,
        setChatCount
        
      }}
    >
      <MainRouter />
    </AppContext.Provider>
    // </BrowserRouter>
  );
}

export default App;
