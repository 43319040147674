import React, { useContext } from 'react';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { ProfileSliderContext } from '../../appContext';

function ProfileTopNavigation({ pageTitle }) {
  const [slider,setSlider] = useContext(ProfileSliderContext);
  const handelSlider = () => {
    setSlider(!slider);
  };
  return (
    <div className="flex items-center gap-2">
        <div className="active:bg-blue-50 inline-flex justify-center items-center lg:hidden">
        <Link to={'/profile'} onClick={handelSlider}>
          <HiOutlineArrowSmallLeft className="text-2xl text-coalBlack active:bg-sky-50" />
        </Link>
        </div>
      <h1 className="headingText capitalize">
        {pageTitle ? pageTitle : ''}
      </h1>
    </div>
  );
}

export default ProfileTopNavigation;
