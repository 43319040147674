import React, { useContext, useState } from 'react';
import MobileModel from '../Modals/MobileModel';
import MainOddsPopup from '../oddsPopup/MainOddsPopup';
import { WalletContext } from '../../appContext';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { FormErrorMessage, InputErrorMessage } from '../errorMessages';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
function GetPayoutAmount({
  setStep,
  balance,
  setAmountrequested,
  recentRequestAmount,
}) {
  const [handelWithdrawBlock, doctorProfile] = useContext(WalletContext);
  const [amount, setAmount] = useState(null);
  const handleNextStep = () => {
    setStep(2);
  };


  const MarkAsPaidValidation = Yup.object().shape({
    amount: Yup.number()
      .max(
        balance - recentRequestAmount(),
        'Entered amount must be less than or equal to wallet balance'
      )
      .required('Amount is required'),
  });

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: MarkAsPaidValidation,
    onSubmit: async (values) => {
      const { amount } = values;
      setAmountrequested(amount);
      handleNextStep();
    },
  });
  const accountCardBody = (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <div className="flex items-center mb-2">
          <p className="text-coalBlack subHeadingText py-2 capitalize">
            Enter Payout Amount
          </p>
          <p
            className=" text-navLink ms-auto hidden lg:block baseText cursor-pointer"
            onClick={handelWithdrawBlock}
          >
            Close
          </p>
        </div>
        <div>
          <input
            placeholder="Enter payout amount"
            className="form-control"
            type="tel"
            id="amount"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
            }}
          />
          <InputErrorMessage
            error={formik.touched.amount && formik.errors.amount}
            marginTop={2}
          />
        </div>
        <div className="my-4 mb-16">
          
          <button
            type="submit"
            className={
              'simple-button primary rounded-2xl text-white capitalize w-full'
            }
            onSubmit={formik.handleSubmit}
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
  return (
    <div>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={accountCardBody}
          popupCloseFunction={handelWithdrawBlock}
        />
      </div>
      <div className="hidden  lg:block">
        <MainOddsPopup>
          <div className="min-w-96">{accountCardBody}</div>
        </MainOddsPopup>
      </div>
    </div>
  );
}

export default GetPayoutAmount;
