import React, { useContext, useEffect, useState } from 'react';
import DashboardSidenav from '../Sidebar/Sidebar';
import SearchHeader from './SearchHeader';
import { AppContext } from '../../appContext';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import WeavyToastNotification from '../oddsChat/component/WeavyToastNotification';
import { getUserApi } from '../../apis';
import { logoutOnJwtExpire } from '../../utils/helperFunctions';
const AdminLayout = ({ children }) => {
  const { userDetails } = useContext(AppContext);
  const urlPathLocation = window.location.pathname;
   
  const navigate = useNavigate();
    const fetchData = async () => {
      try {
        const res = await getUserApi();
        if (res.status === 200) {
          console.log(res.data.Profile);
        }
      } catch (error) {
        console.error(error);
        console.log(error?.response)
                   if (error?.response?.status === 401) {
                           logoutOnJwtExpire();
                           navigate('/sign-in');
                         }
      }
    };
    useEffect(() => {
      fetchData();
    }, []);
  return (
    <div className="flex ">
      <DashboardSidenav />
      <div className="w-full min-h-screen bg-[#FAFAFE] xl:bg-bodybackground">
        <SearchHeader />
        <div className={'content '}>{children}</div>
      </div>
      <WeavyToastNotification/>
      
    </div>
  );
};
export default AdminLayout;
