import React, { useContext } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoNotifications } from 'react-icons/io5';
import { HiMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';
import ProfileDefault from '../../Assets/img/logos/place_profile.png';
function SearchHeader() {
  const { userDetails,chatCount } = useContext(AppContext);
  let badgeElm ;
  if(chatCount >0){
    badgeElm =<span className="p-1 inline-flex justify-center items-center min-w-[18px] h-[18px] rounded-full bg-red-500 text-white !font-semibold smallText absolute top-0 right-0">
      {chatCount}
    </span>;
  }else{
    badgeElm =<div className="w-2 h-2 bg-gray-300 absolute top-1.5 right-2 rounded-full"></div>;
  }
  return (
    <div className="justify-between items-center py-6 px-6 bg-white hidden lg:flex xl:px-10 sticky top-0 z-[899] border-b border-gray-100">
      <div className="w-fit flex gap-4 items-center xl:gap-6 ms-auto">
        <Link to={'/notification'} className="relative">
          <IoNotifications className="text-2xl text-gray-400 p-1.5 hover:bg-gray-50 rounded-full w-9 h-9 cursor-pointer" />
          {badgeElm}
        </Link>
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 bg-white-400 rounded-full bg-avatar">
            <img src={userDetails?.DoctorProfile?.coverPic ? `${process.env.REACT_APP_API_URL}/${userDetails?.DoctorProfile?.coverPic}` : ProfileDefault}  className='block object-cover rounded-full w-full h-full'/>
          </div>
          <p className="baseText text-navLink capitalize">
            {userDetails?.DoctorProfile?.DoctorName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SearchHeader;
