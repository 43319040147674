import React, { useEffect, useReducer } from 'react';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import { NotifyMeAPi, getProfileById, profileCreate } from '../../apis/index.js';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from './RegistrationForm.js';
import VerifyScreen from './VerifyScreen.js';
import toast from 'react-hot-toast';
import RejectScreen from './RejectScreen.js';

function Registration() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState('');
  const [userApprovalLoader, setCUserApprovalLoader] = useState(true);
  const [step, setstep] = useState(0); 
  function prevStep() {
    if (step > 0) {
      setstep(step - 1);
    }
  }
  function nextStep() {
    setstep(step + 1);
  }
  const [notifyloader,setnotifyloader]=useState(false);
  async function notifyFunction() {
    try {
      setnotifyloader(true)
      const res = await NotifyMeAPi({"profileId":localStorage.getItem('userId')});
      if (res.status === 200) {
        console.log(res.data);
        toast.success(res?.data?.message, { id: '001' });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    }
    finally{
      setnotifyloader(false)
    }
  }
  const [RjReason,setRjReason]=useState('');
  const checkUserApproval = async (id, token) => {
    try {
      const res = await getProfileById(id);
      if (res.status === 200) {
        setProfileData(res.data.Profile);
        console.log(res.data.Profile.DoctorProfile);
        if (res.data.Profile.DoctorProfile != null) {
          if (res.data.Profile.DoctorProfile.isApproved == true) {
            navigate('/');
          } else {
            if(res.data.Profile.DoctorProfile?.isRejected){
              setRjReason(res.data.Profile.DoctorProfile?.RejectNotes);
              setstep(4);
            }
            else{
              setstep(3);
            }
          }
        } else {
          setstep(1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkUserApproval(localStorage.getItem('userId'));
    console.log(localStorage.getItem('userId'));
  }, []);
  switch (step) {
  case 1:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-center justify-center bg-white">
        <VerifyScreen
          profileData={profileData}
          nextStep={nextStep}
          prevStep={prevStep}
          title={'Verify your account'}
          subTitle={
            'Complete the verification process by providing your personal details to accept consultations.'
          }
          btntext={'Take Me to Registration'}
          imgSrc={require('../../Assets/img/registration/verifyregistration.png')}
          btnFunction={nextStep}
        />
        <RightBlock
         
        />
      </section>
    );
    break;
  case 2:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-start justify-center bg-white">
        <RegistrationForm prevScreen={prevStep} nextScreen={nextStep} />
        <RightBlock
        
        />
      </section>
    );
    break;
  case 3:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-center justify-center bg-white">
        <VerifyScreen
          profileData={profileData}
          nextStep={nextStep}
          prevStep={prevStep}
          title={'Admin authorization pending'}
          subTitle={
            'The details you have submitted is still pending for verification from admin side.'
          }
          btntext={notifyloader ? <EditProfileLoader /> : 'Notify Me once verified'}
          imgSrc={require('../../Assets/img/registration/auth.png')}
          btnFunction={notifyFunction}
        />
        <RightBlock
          
        />
      </section>
    );
  case 4:
      return (
        <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-center justify-center bg-white lg:h-screen">
          <RejectScreen
            nextStep={nextStep}
            prevStep={prevStep}
            title={'Application denied'}
            subTitle={
              RjReason
            }
            btntext={notifyloader ? <EditProfileLoader /> : 'Reapply'}
            imgSrc={require('../../Assets/img/registration/5366214.jpg')}
            btnFunction={notifyFunction}
            profileData={profileData}
            setprofileData={setProfileData}
          />
          <RightBlock
            imgUrl={
              'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVnaXN0cmF0aW9uJTIwZm9ybXxlbnwwfHwwfHx8MA%3D%3D'
            }
          />
        </section>
      );
    
  default:
    return (
      <div className="min-h-dvh flex justify-center items-center w-full">
        <EditProfileLoader />
      </div>
    );
    break;
  }
}

export default Registration;
