import React, { useContext, useEffect, useState } from 'react';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import MobileModel from '../Modals/MobileModel';
import { useFormik } from 'formik';
import { GetBankAccountDetails } from '../Validation';
import { WalletContext } from '../../appContext';
import toast from 'react-hot-toast';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import MainOddsPopup from '../oddsPopup/MainOddsPopup';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';

import {
  CreateDoctorBankAccount,
  DeleteDoctorBankAccount,
  getDoctorBankAccount,
  updateDoctorBankAccount,
} from '../../apis';
import { Loader } from '../common/loader';
import { InputErrorMessage } from '../errorMessages';

function EditBankDetails({ setStep }) {
  const [acctDetails, setAcctDetails] = useState(null);
  const [handelWithdrawBlock, doctorProfile] = useContext(WalletContext);
  const [loader, setLoader] = useState(true);
  const formik = useFormik({
    initialValues: {
      holderName: acctDetails?.accountHolderName || '',
      accountNumber: acctDetails?.accountNumber || '',
      ifscCode: acctDetails?.ifscCode || '',
    },
    validationSchema: GetBankAccountDetails,
    enableReinitialize: true,
    onSubmit: async (values) => {
     handelConfirmPopUp();
    },
  });
  async function ProceedAddaccount(){
    const { holderName, accountNumber, ifscCode } = formik.values;
    const payload = {
      Id: acctDetails?._id,
      // _id: '662b96fb3fe5842aba5acd3d',
      accountHolderName: holderName,
      accountNumber: accountNumber,
      ifscCode: ifscCode,
    };
    try {
      const res = await updateDoctorBankAccount(payload);
      if (res.status == 200) {
        toast.success('Account Updated Successfully');
        setStep(2);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const fetchBankAccount = async (id) => {
    try {
      const res = await getDoctorBankAccount(id);
      if (res.status == 200) {
        setLoader(!loader);
        setAcctDetails(res.data.user[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(!loader);
    }
  };
  const deleteAccount = async (id) => {
    try {
      const res = await DeleteDoctorBankAccount(id);
      if (res.status == 200) {
        setStep(1);
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBankAccount(doctorProfile?.DoctorProfile?._id);
  }, []);
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const [conformPopUp, setConformPopUp] = useState(false);
  const [deleteConformPopUp, setDeleteConformPopUp] = useState(false);
  function handelConfirmPopUp() {
    setConformPopUp(!conformPopUp);
  }
  function handelConfirmDeletePopUp() {
    setDeleteConformPopUp(!deleteConformPopUp);
  }

  const accountCardBody = (
    <>
      {loader ? (
        <div className="min-h-60 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <div
              className="inline-flex justify-center items-center size-10 rounded-full active:bg-blue-50 cursor-pointer"
              onClick={() => {
                setStep(2);
              }}
            >
              <HiOutlineArrowSmallLeft className="text-coalBlack text-2xl" />
            </div>

            <p className="text-coalBlack subHeadingText py-2 capitalize ">
              Edit Bank Account
            </p>
            <p
              className="baseText text-navLink ms-auto hidden lg:block  cursor-pointer"
              onClick={handelWithdrawBlock}
            >
              close
            </p>
          </div>
          <form className="!my-4 space-y-4">
            <div className="AccountHolderName">
              <p className="baseText text-coalBlack mb-2 capitalize">
                Account Holder Name
              </p>
              <div className="relative">
                <input
                  type="text"
                  name="holderName"
                  className={
                    Boolean(formik.touched.holderName) &&
                    Boolean(formik.errors.holderName)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="holderName"
                  placeholder="Enter name"
                  value={formik.values.holderName}
                  onChange={handleChange}
                />
              </div>
              {
            (formik.touched.holderName && formik.errors.holderName) &&
            <InputErrorMessage
            error={formik.touched.holderName && formik.errors.holderName}
            marginTop={4}
          />
          }
            </div>
            <div className="accountNumber">
              <p className="baseText text-coalBlack mb-2 capitalize">
                Bank Account Number
              </p>
              <div className="relative">
                <input
                  type="text"
                  name="accountNumber"
                  className={
                    Boolean(formik.touched.accountNumber) &&
                    Boolean(formik.errors.accountNumber)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="accountNumber"
                  placeholder="Enter Bank Account Number"
                  value={formik.values.accountNumber}
                  onChange={handleChange}
                />
              </div>
              {
            (formik.touched.accountNumber && formik.errors.accountNumber) &&
            <InputErrorMessage
            error={formik.touched.accountNumber && formik.errors.accountNumber}
            marginTop={4}
          />
          }
            </div>
            <div className="ifscCode">
              <p className="baseText text-coalBlack mb-2 capitalize">
                IFSC Code
              </p>
              <div className="relative">
                <input
                  type="text"
                  name="ifscCode"
                  className={
                    Boolean(formik.touched.ifscCode) &&
                    Boolean(formik.errors.ifscCode)
                      ? 'form-control border-danger transparent-input-border'
                      : 'form-control transparent-input-border'
                  }
                  id="ifscCode"
                  placeholder="Enter IFSC Code"
                  value={formik.values.ifscCode}
                  onChange={handleChange}
                />
              </div>
              {
            (formik.touched.ifscCode && formik.errors.ifscCode) &&
            <InputErrorMessage
            error={formik.touched.ifscCode && formik.errors.ifscCode}
            marginTop={4}
          />
          }
            </div>
          </form>
          <div className="my-4 grid grid-cols-1 gap-4 text-coalBlack"></div>
          <div className="mt-4 mb-6">
            <SimpleButton
              customClass={'rounded-2xl text-white capitalize w-full'}
              buttonType={'primary'}
              title={'Edit Account'}
              onClickEvent={handleSubmit}
            />
            <p className="smallText my-3 text-center text-red-500 !font-medium mx-auto block">
              <button className="mx-auto cursor-pointer" onClick={handelConfirmDeletePopUp}>
                Delete Account?
              </button>
            </p>
          </div>
        </>
      )}
    </>
  );

  return (
    <section>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={accountCardBody}
          popupCloseFunction={handelWithdrawBlock}
        />
      </div>
      <div className="hidden lg:block">
        <MainOddsPopup>
          <div className="min-w-96">{accountCardBody}</div>
        </MainOddsPopup>
      </div>
      {conformPopUp && (
        <ConfirmPopup
          message={'Are you sure you want to update this account?'}
          handelConfirmPopUp={handelConfirmPopUp}
          popupStatus={conformPopUp}
          onclickFunction={ProceedAddaccount}
        />
      )}
      {deleteConformPopUp && (
        <ConfirmPopup
          message={'Are you sure you want to delete your account?'}
          handelConfirmPopUp={handelConfirmDeletePopUp}
          popupStatus={deleteConformPopUp}
          onclickFunction={() => {
            deleteAccount(acctDetails?._id);
          }}
        />
      )}
    </section>
  );
}

export default EditBankDetails;
