import { FiChevronLeft } from 'react-icons/fi';
import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { XCircleFill } from 'react-bootstrap-icons';
import { FileprofileComponent } from '../../components/upload/FileUploadComponent.js';
import { useDropzone } from 'react-dropzone';
import { fileUploadApi, updateConsultation } from '../../apis/index.js';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton.js';
import toast from 'react-hot-toast';

function UploadPrescription({ closeFunction, data }) {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [profileUploadPercentage, setProfileUploadPercentage] = useState(0);
  const [profileName, setProfileName] = useState('');
  const [profileSize, setProfileSize] = useState('');
  const [profileIsError, setProfileIsError] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileIsError, setFileIsError] = useState(false);
  const [isFile, setIsFile] = useState(false);
  console.log('up',data);
  console.log('up',data?.Prescription?.record[0]?.fileName);
  const formik = useFormik({
    initialValues: {
      Prescription: data?.Prescription?.record[0]?.filePath || '',
      PrescriptionSize:data?.Prescription?.record[0]?.fileSize || '',
      PrescriptionName:data?.Prescription?.record[0]?.fileName || '',
    },
    
    onSubmit: async (values) => {
      setLoader(true);
      const { Prescription, PrescriptionName, PrescriptionSize } = values;

      const payload = {
        Id: data?._id,
        Prescription: {
          record: [
            {
              fileName: PrescriptionName,
              filePath: Prescription,
              fileSize: PrescriptionSize,
            },
          ],
        },
      };
      try {
        const res = await updateConsultation(payload);
        console.log(res);
        if (res.status === 200) {
          toast.success('Uploaded successfully');
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
        toast.error('Some thing went wrong');
      }
    },
  });

  const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;
    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  };

  const onUploadProgress = (progressEvent, file) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercentage(progress);
    setFileName(file.name);
    setFileSize(formatFileSize(file.size));
  };

  const fileUpload = async (file) => {
    try {
      setIsFile(true);
      const formData = new FormData();
      formData.append('file', file);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        formik.setFieldValue('Prescription', uploadResponse.data.filePath);
        formik.setFieldValue('PrescriptionName', file.name);
        formik.setFieldValue('PrescriptionSize', formatFileSize(file.size));
      } else {
        setFileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setFileIsError(true);
    } finally {
      setIsFile(false);
    }
  };

  const profileUpload = async (file) => {
    try {
      setIsProfile(true);
      const formData = new FormData();
      formData.append('file', file);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        formik.setFieldValue('Prescription', uploadResponse.data.filePath);
        formik.setFieldValue('PrescriptionName', file.name);
        formik.setFieldValue('PrescriptionSize', formatFileSize(file.size));
      } else {
        setProfileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setProfileIsError(true);
    } finally {
      setIsProfile(false);
    }
  };

  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profileUpload(file);
    }
  }, []);

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  return (
    <section className="fixed top-0 left-0 min-h-screen h-screen w-full bg-white z-30 xl:bg-black/25 xl:flex xl:justify-center xl:items-center">
      <div className="bg-white relative p-4 pt-0 z-20 m-auto overflow-y-auto scrollbar w-full h-full max-h-screen lg:max-w-4xl xl:max-w-5xl lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl xl:bg-bodybackground overscroll-contain">
        <div className="bg-white flex items-center sticky top-0 py-4 z-30 xl:bg-inherit">
          <div className="flex items-center gap-1">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={closeFunction}
            />
            <p className="subHeadingText text-coalBlack capitalize">
              Upload Prescription
            </p>
          </div>
          <p
            className="text-navLink baseText min-w-10 p-2 cursor-pointer hidden xl:block ms-auto"
            onClick={closeFunction}
          >
            close
          </p>
        </div>
        <div className="uploadProfilePhoto max-w-md mx-auto mt-10 mb-16">
          <p className="baseText text-coalBlack mb-2 capitalize">
            Upload Prescription
          </p>
          <div>
            <div className="flex gap-4 input-group bg-white">
              <div className="w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0">
                {isProfile ? (
                  <FileprofileComponent
                    name={profileName}
                    filesize={profileSize}
                    percentage={profileUploadPercentage}
                  />
                ) : (
                  <>
                    {formik.values.Prescription && (
                      <div className="flex flex-col items-center w-[100%]">
                        <div className="flex flex-row justify-between w-[100%] mb-4">
                          <div className="truncate w-[80%]">
                            {formik.values.PrescriptionName}
                          </div>
                          <XCircleFill
                            className="cursor-pointer"
                            onClick={() =>
                              formik.setFieldValue('Prescription', '')
                            }
                          />
                        </div>
                        <img
                          src={formik.values.Prescription}
                          alt="Prescription"
                        />
                      </div>
                    )}
                    {!formik.values.Prescription && (
                      <div {...getRootPropsProfile()} className="w-[100%]">
                        <input {...getInputPropsProfile()} />
                        {isDragActiveProfile ? (
                          <div className="flex flex-col items-center text-center">
                            <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                              <i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" />
                            </div>
                            <p className="font-semibold text-[#00A46F] mt-6">
                              Drop here
                            </p>
                            <p>PNG, JPG</p>
                          </div>
                        ) : (
                          <div className="flex flex-col items-center text-center">
                            <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                              <i className="bi bi-cloud-arrow-up text-2xl text-[#1648CE]" />
                            </div>
                            <p className="font-semibold text-[#1648CE] mt-6">
                              Click to upload{' '}
                              <span className="text-[#667085] font-normal hidden md:block">
                                or drag and drop
                              </span>
                            </p>
                            <p>PNG, JPG</p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <SimpleButton
            title={'Submit'}
            buttonType={'primary'}
            customClass={'rounded-lg my-4 w-full'}
            onClickEvent={formik.submitForm}
          />
        </div>
      </div>
    </section>
  );
}

export default UploadPrescription;
