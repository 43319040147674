import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserProfile from '../../components/UserProfile/UserProfile';
import { FiLogOut } from 'react-icons/fi';
import { GoArrowUpRight } from 'react-icons/go';
import SideMessageNotification from './SideMessageNotification';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { Link } from 'react-router-dom';
import ViewRequest from '../../components/ConsultationCard/Viewrequest';
import { getProfileById, getRecentRequest } from '../../apis';
import LoaderCard from '../../components/ConsultationCard/LoaderCard';
import { AppContext } from '../../appContext';
import ConsultationHistory from './ConsultationHistory';
function BookingsCard({
  imgUrl,
  bookingTitle,
  bookingCount,
  bookingPercentage,
  bookingFromDate,
}) {
  return (
    <div className="space-y-7 w-full bg-white p-4 rounded-3xl shadow-blue_dropshadow h-full lg:min-h-max">
      <div className="flex items-center gap-4">
        <div className="w-16 h-16">
          <img src={imgUrl} className="w-full aspect-square" />
        </div>
        <p className="headingText text-coalBlack capitalize">{bookingTitle}</p>
      </div>
      <div className="flex gap-2 items-center ps-5">
        <p className="headingText text-coalBlack  text-center">
          {bookingCount}
        </p>
        <p className="subHeadingText text-green-500">
          {/* {' '}
          <GoArrowUpRight className=" text-green-500 inline" />{' '}
          {bookingPercentage}
          <span className="baseText text-navLink ms-1 capitalize">
            {bookingFromDate}
          </span> */}
        </p>
      </div>
    </div>
  );
}

export default BookingsCard;
