import React, { useContext, useEffect, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import { GiReceiveMoney } from 'react-icons/gi';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { BsCurrencyRupee } from 'react-icons/bs';
import { FiChevronRight } from 'react-icons/fi';
import BalanceHistory from '../../components/wallet/BalanceHistory';
import WithdrawHistory from '../../components/wallet/WithdrawHistory';
import TransitionCard from '../../components/wallet/TransitionCard';
import { IoWallet } from 'react-icons/io5';
import { FiArrowRight } from 'react-icons/fi';
import MobileModel from '../../components/Modals/MobileModel';
import { FaCreditCard } from 'react-icons/fa';
import { BsBank } from 'react-icons/bs';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import VerifyBankIndex from '../../components/wallet/VerifyBankIndex';
import { AppContext, WalletContext } from '../../appContext';
import {
  getMonthlyEarnings,
  getProfileById,
  getwalletbalancegraph,
  getWithdrawHistory,
} from '../../apis';
import PaginatedTable from '../../components/TableOdds/PaginatedTable';
import TranstationHistory from './TranstationHistory';
import AmountDue from './AmountDue';
import MonthlyEarnings from './MonthlyEarnings';
import { BiInfoCircle } from 'react-icons/bi';
import { MdOutlineAutoGraph } from 'react-icons/md';
import WithdrawRequest from './WithdrawRequest';
import MainOddsPopup from '../../components/oddsPopup/MainOddsPopup';
import { FiChevronLeft } from 'react-icons/fi';
import moment from 'moment';

function WalletIndex() {
  const { userDetails } = useContext(AppContext);
  console.log("date " + userDetails);
  
  const [withdrawBlock, setWithdrawBlock] = useState(false);
  const [consultationHistoryBlock, setConsultationHistoryBlock] = useState(
    false
  );
  const [doctorProfile, setDoctorProfile] = useState(null);
  const [monthlyEarningsData, setMonthlyEarningsData] = useState(null);
  
  const formattedDate = (date) => {
    if (!date) return null;
    return moment().add(1, 'months').date(date).format('DD/MM/YYYY').toString();
  };  

  const handelWithdrawBlock = () => {
    setWithdrawBlock(!withdrawBlock);
  };
  const getUserDetails = async () => {
    try {
      const res = await getProfileById(userDetails?._id);
      if (res.status === 200) {
        setDoctorProfile(res?.data?.Profile);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toGetMonthlyEarnings = async (payload) => {
    try {
      const res = await getMonthlyEarnings(payload);
      if (res.status === 200) {
        console.log(res.data?.data);
        setMonthlyEarningsData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const payload = {
      doctorId: userDetails?.DoctorProfile?._id,
      month: month <= 9 ? '0' + month : month,
      year: year,
    };
    getUserDetails();
    toGetMonthlyEarnings(payload);
    getMonthlyEarningsAmount();
  }, [userDetails]);
  function getMonthlyEarningsAmount() {
    let total = 0;
    monthlyEarningsData?.map((data) => {
      if (data?.status == 'completed') {
        if (data?.transactions[0]?.doctor_fee != undefined) {
          total = Number(data?.transactions[0]?.doctor_fee) + total;
        }
      }
    });
    return total;
  }
  const [updatePage, setUpdatePage] = useState(0);
  console.log('weavy chat v24.3.1');
  
  return (
    <WalletContext.Provider
      value={[handelWithdrawBlock, doctorProfile, setUpdatePage, updatePage]}
    >
      <AdminLayout>
        <section className=" md:p-4 xl:p-5 xl:pb-0">
          <div className="flex items-center rounded-lg md:bg-white p-4 xl:p-5 ">
            <p className="headingText text-coalBlack  capitalize">Wallet</p>
            <div
              className="gap-2 items-center ms-auto bg-accent rounded-xl text-white min-h-11 px-6 py-2 md:inline-flex hidden cursor-pointer"
              onClick={handelWithdrawBlock}
            >
              <GiReceiveMoney className="text-2xl" />
              <button className="baseText capitalize" type="button">
                withdraw
              </button>
            </div>
            <div className="ms-auto md:hidden">
              <div className="bg-white p-1 rounded-xl border border-gray-300">
                <IoIosHelpCircleOutline className="text-2xl text-accent" />
              </div>
            </div>
          </div>
          {/* sm screen  start*/}
          <div className="lg:hidden">
            <section className="mt-4 p-4">
              <div className="bg-accent text-white p-4 rounded-xl  shadow-blue_dropshadow min-h-32 flex flex-col">
                <div className="pb-4">
                  <div className="inline-flex gap-2 items-center">
                    <IoWallet className="text-2xl text-blue-500 m-auto  p-px rounded-full border border-white/25" />
                    <span className="headingText  ms-1">My Earnings</span>
                  </div>
                </div>
                <div className="flex items-center pt-5 mt-auto border-t border-t-px border-white/50">
                  <p className="highlightText">
                    <BsCurrencyRupee className="me-px inline-block" />
                    {doctorProfile?.DoctorProfile?.walletId?.balance || 0}
                  </p>
                  <div className="ms-auto">
                    <FiArrowRight className="text-2xl text-white" />
                  </div>
                </div>
              </div>

              <div className="bg-white border border-gray-300 text-coalBlack p-4 rounded-xl mt-4  shadow-blue_dropshadow min-h-32 flex flex-col">
                <div className="pb-4">
                  <div className="inline-flex gap-2 items-center">
                    <MdOutlineAutoGraph className="text-2xl text-blue-500 m-auto  p-px rounded-full" />
                    <span className="headingText  ms-1">
                      {new Date().toLocaleString('default', {
                        month: 'long',
                      })}{' '}
                      Earnings
                    </span>
                  </div>
                </div>
                <div className="flex items-center pt-5 mt-auto border-t border-t-px border-gray-300/75 relative">
                  <p className="highlightText ">
                    <BsCurrencyRupee className="me-px inline-block" />
                    {getMonthlyEarningsAmount()}
                  </p>
                  <div className="ms-auto group/info">
                    <BiInfoCircle className="text-2xl text-gray-700" />
                    <p className="baseText text-white absolute bg-coalBlack right-[-7px] p-1  lg:!leading-4 bottom-[-53px] shadow-sm rounded-lg  transition-all ease-linear duration-200 invisible group-hover/info:visible">
                      This amount will be credited to you on{' '}{formattedDate(userDetails?.Settings?.DoctorpayDate)}
                      <span className="size-3 rounded-sm bg-coalBlack absolute top-[-5px] right-3.5 rotate-45"></span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <div className="px-4">
              <WithdrawRequest />
            </div>

            <section className="mt-4 p-4 py-2">
              <div
                className="flex items-center justify-center px-2 py-3 bg-accent shadow-blue_dropshadow rounded-2xl"
                onClick={() => {
                  setConsultationHistoryBlock(!consultationHistoryBlock);
                }}
              >
                <h1 className="bg-accent text-white headingText capitalize">
                  Recent Consultations
                </h1>
                {/* <div className="ms-auto size-9 rounded-full inline-flex justify-center items-center active:bg-blue-50">
                  <FiArrowRight className="text-2xl text-accent" />
                </div> */}
              </div>
            </section>

            <section className="bg-white rounded-2xl p-4 mt-4 mb-[120px]">
              <WithdrawHistory />
            </section>
            <div className="fixed bottom-20 right-4 z-30 md:hidden">
              <div
                className="inline-flex gap-2 items-center ms-auto bg-accent rounded-xl text-white min-h-14 px-4 py-1 "
                onClick={handelWithdrawBlock}
              >
                <GiReceiveMoney className="text-2xl" />
                <button className="baseText capitalize">withdraw</button>
              </div>
            </div>
          </div>
          {/* sm screen  end*/}

          {/* large devices start */}
          <div className=" hidden lg:grid-cols-12 gap-6 lg:grid my-12 ">
            <div className="col-span-12  xl:col-span-8">
              <section className="py-5 bg-white p-4 rounded-2xl grid grid-cols-2 gap-x-4">
                <p className="text-coalBlack subHeadingText capitalize mb-3 col-span-2">
                  overview
                </p>
                <div className="bg-accent text-white p-4 rounded-xl  shadow-blue_dropshadow min-h-32 flex flex-col">
                  <div className="pb-6 ">
                    <div className="inline-flex gap-2 items-center">
                      <IoWallet className="text-2xl text-blue-500 m-auto  p-px rounded-full border border-white/25" />
                      <span className="headingText  ms-1">My Earnings</span>
                    </div>
                  </div>
                  <div className="flex items-center pt-5 mt-auto border-t border-t-px border-white/50">
                    <p className="highlightText">
                      <BsCurrencyRupee className="me-px inline-block" />
                      {doctorProfile?.DoctorProfile?.walletId?.balance || 0}
                    </p>
                    <div className="ms-auto">
                      <FiArrowRight className="text-2xl text-white" />
                    </div>
                  </div>
                </div>
                <div className="bg-white border border-gray-300 text-coalBlack p-4 rounded-xl   shadow-blue_dropshadow min-h-32 flex flex-col">
                  <div className="pb-6 ">
                    <div className="inline-flex gap-2 items-center">
                      <MdOutlineAutoGraph className="text-2xl text-blue-500 m-auto  p-px rounded-full" />
                      <span className="headingText  ms-1">
                        {new Date().toLocaleString('default', {
                          month: 'long',
                        })}{' '}
                        Earnings
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center pt-5 mt-auto border-t border-t-px border-gray-300/75 relative">
                    <p className="highlightText ">
                      <BsCurrencyRupee className="me-px inline-block" />
                      {getMonthlyEarningsAmount()}
                    </p>
                    <div className="ms-auto group/info hover:shadow-md rounded-full">
                      <BiInfoCircle className="text-2xl text-gray-700 " />
                      <p className="smallText text-white absolute bg-coalBlack -right-2.5  p-3  lg:!leading-4 -bottom-12  transition-all ease-linear duration-200 invisible group-hover/info:visible shadow-sm rounded-md">
                        This amount will be credited to you on{' '}{formattedDate(userDetails?.Settings?.DoctorpayDate)}
                        <span className="size-3 rounded-sm bg-coalBlack absolute top-[-5px] right-3.5 rotate-45"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="bg-white rounded-2xl shadow-md p-1 mt-4">
                <WithdrawHistory />
              </section>
            </div>
            <div className="col-span-12 hidden lg:block max-h-screen xl:col-span-4">
              <section className="flex flex-col gap-y-4 h-full ">
                <>
                  <WithdrawRequest />
                </>
                <div className="bg-white rounded-2xl overflow-y-auto  scrollbar h-full">
                  <h1 className="text-coalBlack subHeadingText p-4 py-5 sticky top-0 bg-white border-b border-gray-100">
                    Recent Consultations
                  </h1>
                  <MonthlyEarnings data={monthlyEarningsData} />
                </div>
              </section>
            </div>
          </div>
          {/* large devices end */}

          {/* select cards start */}
          {withdrawBlock && (
            <VerifyBankIndex
              balance={doctorProfile?.DoctorProfile?.walletId?.balance || 0}
            />
          )}
          {/* select cards end */}

          {/* consultationHistoryBlock start  */}
          {consultationHistoryBlock && (
            <div className="lg:hidden">
              <MainOddsPopup>
                <div className="min-h-screen h-screen bg-white overflow-y-auto scrollbar overscroll-contain pb-20">
                  <header className="p-4 sticky top-0 bg-white z-40 border-b border-gray-100">
                    <div className="flex items-center">
                      <div className="inline-flex gap-1 items-center w-full">
                        <FiChevronLeft
                          className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50 lg:hidden"
                          onClick={() => {
                            setConsultationHistoryBlock(
                              !consultationHistoryBlock
                            );
                          }}
                        />
                        <p className="headingText text-coalBlack align-self-center capitalize">
                          Recent Consultations
                        </p>
                      </div>
                    </div>
                  </header>
                  {monthlyEarningsData && monthlyEarningsData.length > 0 ?  
                    <MonthlyEarnings data={monthlyEarningsData} /> :
                    <p className="headingText text-coalBlack align-self-center capitalize text-nowrap fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                    No consultations found
                  </p>
                  }
                </div>
              </MainOddsPopup>
            </div>
          )}
          {/* consultationHistoryBlock end */}
        </section>
      </AdminLayout>
    </WalletContext.Provider>
  );
}

export default WalletIndex;
