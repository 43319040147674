import React, { useContext, useEffect, useReducer, useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FaStarOfLife } from 'react-icons/fa6';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  DoctorRegistrationStepFour,
  DoctorRegistrationStepOne,
  DoctorRegistrationStepThree,
  DoctorRegistrationStepTwo,
} from '../../components/Validation/index.js';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import { BiBell } from 'react-icons/bi';
import { FiChevronLeft } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';
import { LuUpload } from 'react-icons/lu';
import { PiCertificateFill } from 'react-icons/pi';
import { BsFileMedicalFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { FaFileAlt } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa6';
import MobileModel from '../../components/Modals/MobileModel.js';
import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import {
  getProfileById,
  getSpecialization,
  profileCreate,
} from '../../apis/index.js';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../components/UserProfile/UserProfile.js';
import Select from 'react-select';
import { AppContext } from '../../appContext/index.js';
import axios from 'axios';
function RegisterTwo({ formik, loader, specialization }) {
  const inputRef = useRef(null);
  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const openDatePicker = () => {
    if (
      'showPicker' in inputRef.current &&
      typeof inputRef.current.showPicker === 'function'
    ) {
      inputRef.current.showPicker();
    }
  };

  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const specializationOption = specialization?.map((data) => {
    return { label: capitalize(data?.name), value: data?._id };
  });
  const [selectedOption, setSelectedOption] = useState(
    formik.values.specialization != ''
      ? formik.values.chosenOption
      : 'Select a option'
  );

  const handleOption = (selectedOption) => {
    setSelectedOption(selectedOption.label);
    formik.setFieldValue('specialization', selectedOption.value);
    formik.setFieldValue('chosenOption', selectedOption.label);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="pb-6">
      <section className="space-y-4 shadow-md rounded-xl p-4 md:shadow-none">
        <h3 className="text-lg capitalize font-medium">
          Professional Details{' '}
        </h3>
        <div className="specialization">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize ">
            Specialization
          </p>
          <div></div>
          <div className="relative">
            <div className="">
              <Select
                name="specialization"
                placeholder={selectedOption}
                classNamePrefix="specializationSelect"
                options={specializationOption}
                value={selectedOption}
                onChange={handleOption}
              />
            </div>
            <InputErrorMessage
              error={
                formik.touched.specialization && formik.errors.specialization
              }
            />
          </div>
          {/* <div className="relative">
            <input
              type="text"
              name="specialization"
              className={
                Boolean(formik.touched.specialization) &&
                Boolean(formik.errors.specialization)
                  ? "form-control border-danger transparent-input-border"
                  : "form-control transparent-input-border"
              }
              id="specialization"
              placeholder="M.D Orthopedic Surgeon"
              value={formik.values.specialization}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={
                formik.touched.specialization && formik.errors.specialization
              }
            />
          </div> */}
        </div>
        <div className="regNumber">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Registration Number
          </p>
          <div className="relative">
            <input
              type="text"
              name="regNumber"
              className={
                Boolean(formik.touched.regNumber) &&
                Boolean(formik.errors.regNumber)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="regNumber"
              placeholder="474RHFBR57HY"
              value={formik.values.regNumber}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.regNumber && formik.errors.regNumber}
            />
          </div>
        </div>
        <div className="smCouncil">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            State Medical Council
          </p>
          <div className="relative">
            <input
              type="text"
              name="smCouncil"
              className={
                Boolean(formik.touched.smCouncil) &&
                Boolean(formik.errors.smCouncil)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="smCouncil"
              placeholder="Ex: Tamil Nadu Medical Council"
              value={formik.values.smCouncil}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.smCouncil && formik.errors.smCouncil}
            />
          </div>
        </div>
        <div className="dateOfReg">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Date of Registration
          </p>
          <div className="relative">
            <input
              ref={inputRef}
              type="date"
              name="dateOfReg"
              className={
                Boolean(formik.touched.dateOfReg) &&
                Boolean(formik.errors.dateOfReg)
                  ? 'form-control datePlaceholder border-danger transparent-input-border'
                  : 'form-control datePlaceholder transparent-input-border'
              }
              id="dateOfReg"
              placeholder="28/09/1966"
              value={formik.values.dateOfReg}
              onChange={handleChange}
              onClick={openDatePicker}
            />
            <InputErrorMessage
              error={formik.touched.dateOfReg && formik.errors.dateOfReg}
            />
          </div>
        </div>
        <div className="submitButton mt-4">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Next'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </section>
    </form>
  );
}

export default RegisterTwo;
