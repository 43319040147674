import { Form, useFormik } from 'formik';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { HelpcenterValidation } from '../../components/Validation';
import { EditProfileLoader } from '../../components/common/loader';
import { InputErrorMessage } from '../../components/errorMessages';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import toast from 'react-hot-toast';
import ProfileTopNavigation from './ProfileTopNavigation';
import { FreshdeskApi, getUserApi } from '../../apis';

function HelpCenter() {
  const [profileData, setprofileData] = useState('');
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log('profileData', profileData);
  const [editloader, seteditloader] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      registeredemail: '',
      subject: '',
      description: '',
      registeredmobile: '',
    },
    enableReinitialize: true,
    validationSchema: HelpcenterValidation,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      seteditloader(true);
      const { name, registeredemail, registeredmobile, subject, description } = values;
      const payloadFormate = {
        userId: profileData?._id,
        name: name,
        subject: subject,
        registered_email: registeredemail,
        registered_mobile: registeredmobile,
        description: description,
      };
      try {
        const res = await FreshdeskApi(payloadFormate);
        if (res.status === 200) {
          toast.success('Thank you for your feedback! We appreciate your input.');
          resetForm(); // Reset the form fields after successful submission
        }
      } catch (error) {
        console.log(error.response.data.error);
        const message = error.response.data.error || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        seteditloader(false);
      }
    },
  });
  

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  return (
    <div className="text-coalBlack pb-[20%]">
      <ProfileTopNavigation pageTitle={'Help Center'} />
      <section className="my-7 max-w-md me-auto px-4">

        <form className="mt-11 space-y-4">
          <h2 className="subHeadingText">Contact us</h2>

          <div className="name">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Your name
            </p>
            <div className="relative">
              <input
                type="text"
                name="name"
                className={
                  Boolean(formik.touched.name) &&
                    Boolean(formik.errors.name)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="name"
                placeholder="Enter your name"
                value={formik.values.name}
                title={formik.values.name}
                onChange={handleChange}
                maxLength={30}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z. ]/g, ""); // Remove invalid characters
                }}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.name && formik.errors.name}
            />
          </div>
          <div className="registeredemail">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Registered Email ID
            </p>
            <div className="relative">
              <input
                type="text"
                name="registeredemail"
                className={
                  Boolean(formik.touched.registeredemail) &&
                    Boolean(formik.errors.registeredemail)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="registeredemail"
                placeholder="Enter Registered Email ID"
                value={formik.values.registeredemail}
                title={formik.values.registeredemail}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.registeredemail && formik.errors.registeredemail}
            />
          </div>
          <div className="registeredmobile">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Registered mobile number
            </p>
            <div className="relative">
              <input
                type="tel"
                name="registeredmobile"
                className={
                  Boolean(formik.touched.registeredmobile) &&
                    Boolean(formik.errors.registeredmobile)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="registeredmobile"
                placeholder="Enter mobile number"
                value={formik.values.registeredmobile}
                title={formik.values.registeredmobile}
                onChange={handleChange}
                maxLength={10} // Ensures max 10 characters
                pattern="\d{10}" // Ensures exactly 10 digits in a form submission
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10); // Allows only digits
                }}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.registeredmobile && formik.errors.registeredmobile}
            />
          </div>
          <div className="subject">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Subject (Your issue in short)
            </p>
            <div className="relative">
              <input
                type="text"
                name="subject"
                className={
                  Boolean(formik.touched.subject) &&
                    Boolean(formik.errors.subject)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="subject"
                placeholder="Enter Subject"
                value={formik.values.subject}
                title={formik.values.subject}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.subject && formik.errors.subject}
            />
          </div>
          <div className="description">
            <p className="baseText text-coalBlack mb-2 capitalize">
              Description (Your issue in detail)
            </p>
            <div className="relative">
              <textarea
                name="description"
                className={
                  Boolean(formik.touched.description) &&
                    Boolean(formik.errors.description)
                    ? 'pe-9 form-control line-clamp-1 border-danger transparent-input-border'
                    : 'pe-9 form-control line-clamp-1 transparent-input-border'
                }
                id="description"
                placeholder="Description"
                value={formik.values.description}
                title={formik.values.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <InputErrorMessage
              error={formik.touched.description && formik.errors.description}
            />
          </div>

          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={editloader ? <EditProfileLoader /> : 'Send'}
            onClickEvent={formik.handleSubmit}
          />
        </form>
      </section>
    </div>
  );
}

export default HelpCenter;
