import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MobileEventCalender from './MobileEventCalender';
import {
  getCalenderConsultation,
  getDoctorApibyUserID,
  getProfileById,
  getRecentRequest,
} from '../../apis';
import { AppContext } from '../../appContext';
import toast from 'react-hot-toast';
import ViewDetails from '../ConsultationCard/ViewDetails';

const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const {userDetails} = useContext(AppContext);
  const [viewDetailsBlock, setViewDetailsBlock] = useState(false);
  const [consultationId, setConsultationId] = useState(null);
  const [profileData, setprofileData] = useState('');
  const [events, setEvents] = useState();

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '#3174ad';
    let color = 'black';
    let cursor = 'pointer';
    const opacity = isSelected ? 0.8 : 1;
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    const startDate = new Date(start);
    const startYear = startDate.getFullYear();
    const startmonth = startDate.getMonth() + 1;
    const startday = startDate.getDate();
    const startformattedMonth = startmonth < 10 ? `0${startmonth}` : startmonth;
    const startformattedDay = startday < 10 ? `0${startday}` : startday;
    const startformattedDate = `${startYear}-${startformattedMonth}-${startformattedDay}`;
    const isPastEvent = formattedDate <= startformattedDate;
    switch (event.type) {
    case 'inperson':
      (backgroundColor = isPastEvent ? '#D5EBDF' : '#eeeeee'),
      (color = isPastEvent ? '#1AA053' : '#ccc');
      cursor = isPastEvent ? 'pointer' : 'pointer';
      break;
    case 'Virtual':
      backgroundColor = isPastEvent ? '#C4CCF8' : '#eeeeee';
      color = isPastEvent ? '#3A57E8' : '#ccc';
      cursor = isPastEvent ? 'pointer' : 'pointer';
      break;
    default:
      backgroundColor = '#3174ad';
      color = 'white';
    }

    const style = {
      backgroundColor: backgroundColor,
      opacity: opacity,
      borderRadius: '0px',
      color: color,
      border: '0px',
      display: 'block',
      boxShadow: `0px 0px 0px 2px ${color}`,
      cursor: cursor,
    };

    return {
      style: style,
    };
  };

  const Toolbar = ({ label, onNavigate, onView }) => (
    <div className="rbc-toolbar !grid !gap-4 md:!justify-normal lg:!flex !mb-[7%]">
      <span className="flex gap-4 items-center justify-between">
        <span className="rbc-btn-group">
          <button type="button" onClick={() => onNavigate('TODAY')}>
            Today
          </button>
        </span>
        <span className="rbc-btn-group !flex !gap-4 ">
          <button type="button" onClick={() => onNavigate('PREV')}>
            &lt;
          </button>
          <button type="button" onClick={() => onNavigate('NEXT')}>
            &gt;
          </button>
        </span>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      <span className="rbc-btn-group !flex gap-2 !justify-center ">
        <button type="button" onClick={() => onView('month')}>
          Month
        </button>
        <button type="button" onClick={() => onView('week')}>
          Week
        </button>
        <button type="button" onClick={() => onView('day')}>
          Day
        </button>
        <button type="button" onClick={() => onView('agenda')}>
          Agenda
        </button>
      </span>
    </div>
  );
  const handleShowMore = (events, date) => {
    console.log('More events:', events);
    console.log('On date:', date);
  };
  const handleEventSelect = (event) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    // if (event.start < formattedDate) {
    //   toast.error(
    //     <p className="text-center">The Past Event Cannot Be Selected.</p>
    //   );
    //   return;
    // }
    console.log('Selected Event:', event.id);
    setConsultationId(event.id);
    handleViewDetails();
  };

  const getUserDetails = async () => {
    try {
      const res = await getProfileById(userDetails?._id);
      if (res.status === 200) {
        const id = res.data.Profile?.DoctorProfile?._id;
        onGetCalenderConsultation(id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function onGetCalenderConsultation(id) {
    try {
      const res = await getCalenderConsultation(id);
      if (res.status == 200) {
        const updateArray = res.data.calendardata.map((data) => {
          let title;
          if (data.title === 'Remote Consultation') {
            title = 'Remote';
          } else if (data.title === 'In-person Consultation') {
            title = 'In-person';
          }
          return {
            ...data,
            allDay: true,
            // title:'In-person Consultation'?'In-person':'Remote',
            title:title,
          };
        });
        console.log(updateArray);
        setEvents(updateArray);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getUserDetails();
  }, [userDetails]);
  const handleViewDetails = (id) => {
    if (!viewDetailsBlock) {
    }
    setViewDetailsBlock(!viewDetailsBlock);
  };
  return (
    <section>
      <div
        style={{ minHeight: '800px' }}
        className="lg:bg-white bg-transparent rounded-lg mb-16 "
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor={(event) => {
            return new Date(event.start);
          }}
          endAccessor="end"
          style={{ minHeight: '800px' }}
          eventPropGetter={eventStyleGetter}
          components={{
            toolbar: Toolbar,
          }}
          views={['month', 'week', 'day', 'agenda']}
          popup={true}
          handleShowMore={handleShowMore}
          onSelectEvent={handleEventSelect}
        />
      </div>
      {viewDetailsBlock && (
        <ViewDetails
          profileData={profileData}
          closeFunction={handleViewDetails}
          currentdata={consultationId}
        />
      )}
    </section>
  );
};

export default EventCalendar;
