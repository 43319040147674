import React, { useState,useContext } from 'react';
import ProfileTopNavigation from './ProfileTopNavigation';
import { InputErrorMessage } from '../../components/errorMessages';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { useFormik } from 'formik';
import { deleteAcctPassword } from '../../components/Validation';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import MandatoryLabel from '../../components/common/label';
import { DeleteAccountAPI, NewregisteratoinApi } from '../../apis';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../../components/loader/loader';
import ConfirmPopup from '../../components/confirmPopup/ConfirmPopup';
import toast from 'react-hot-toast';
function DeleteAccount() {
  const {userDetails} = useContext(AppContext);

  const [passwordType, setPasswordType] = useState('password');
  const [loader,setLoader]=useState(false);
  const [declinePopup, setDeclinePopup] = useState(false);
  const { logout } = useContext(AppContext);
  function handelConfirmPopUp() {
    setDeclinePopup(!declinePopup);
  }
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: deleteAcctPassword,
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const res = await DeleteAccountAPI({
            
          'userid': userDetails?._id,
          'password': password,
          
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          setDeclinePopup(false);
          logout();
        }
      } catch (error) {
        console.log(error.response.data.error);
        
        setLoader(false);
        const message =
          error.response.data.error || error.response.statusText;
        toast.error(message, { id: 'error' });
        setDeclinePopup(false);
      }
    },
  });
  const { password } = formik.values;
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  return (
    <div className="text-coalBlack pb-[25%]">
      <ProfileTopNavigation pageTitle={'Delete account'} />
      <section className="my-8">
        <div>
          <img
            src={require('../../images/Account deleting.png')}
            className="max-w-xs object-contain"
          />
        </div>
        <div className="my-4 baseText !font-medium max-w-xs">
          Deleting your account will make it immediately inaccessible
        </div>
        <p className="max-w-md baseText  text-navLink ">
          All your data and settings will be deleted immediately.
        </p>
        <form className="mt-12 space-y-4 max-w-md">
          <h2 className="baseText !font-medium">
            Please verify your account before deleting.
          </h2>
          <div className="relative">
            <MandatoryLabel title="Enter your password" />
            <div className="relative">
              <input
                type={passwordType}
                name="password"
                className={
                  Boolean(formik.touched.password) &&
                  Boolean(formik.errors.password)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="password"
                placeholder="Password "
                value={formik.values.password}
                onChange={handleChange}
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
                onClick={handelPasswordType}
              >
                {passwordType == 'password' ? (
                  <IoEyeOff />
                ) : passwordType == 'text' ? (
                  <IoEye />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <InputErrorMessage
              error={formik.touched.password && formik.errors.password}
            />
          </div>

          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={loader ? <EditProfileLoader /> : 'Delete Account'}
            onClickEvent={(e) => {
              e.preventDefault();
              if (formik.isValid && password) {
                handelConfirmPopUp(); 
              } else {
                formik.handleSubmit(); 
              }
            }}
            buttonFunction={'openModal'}
          />
          {declinePopup && <ConfirmPopup
              message={"Are you sure you want to delete your account?"}
              popupStatus={declinePopup}
              handelConfirmPopUp={()=>handelConfirmPopUp()}
              onclickFunction={formik.handleSubmit}
              forDeleteAccount={true}
              />}
        </form>
      </section>
    </div>
  );
}

export default DeleteAccount;
