import React, { useState } from 'react';
import { PiStethoscopeFill } from 'react-icons/pi';
import { FaBriefcaseMedical } from 'react-icons/fa';
import { IoVideocam } from 'react-icons/io5';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../common/loader';
import SimpleModal from '../Modals/SimpleModal';
import { Trash, Trash2Fill } from 'react-bootstrap-icons';
import Avatar from '../Avatar/Avatar';
import { MdWorkspacePremium } from "react-icons/md";
function ConsultationCard({
  data,
  editRequest,
  createRequest,
  ViewRequest,
  onload,
  fillBtnTitle,
  fillBtnfun,
  unFillBtnTitle,
  unFillBtnfun,
}) {
  const [loader, setLoader] = useState(false);
  //alert(JSON.stringify(data))
  function getConsultationRequestStyle() {
    const style = {
      urgent: 'bg-red-200 text-red-500',
      regular: 'bg-green-200 text-green-500',
    };
    return style[data?.priority];
  }
  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  function gettypePriorityText() {
    const text = {
      regular: 'Routine',
      urgent: 'Urgent',
    };
    return text[data?.priority];
  }
  const [deletepopup, setdeletepopup] = useState(false);
  const deleteconsultation = async () => {
    // try {
    //   setLoader(true);
    //   const res = await DeleteconsultationAPI(data?._id);
    //   if (res.status === 200) {
    //     toast.success(res.data.message);
    //     onload();
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setLoader(false);
    //   const message =
    //     error?.response?.data?.message || error?.response?.statusText;
    //   toast.error(message, { id: "error" });
    // } finally {
    //   setLoader(false);
    // }
  };
  let ispage = true;

  return (
    <div className="consultationCard bg-white space-y-4 shadow-card_shadow px-2 py-6 rounded-2xl md:px-3">
      <div className="cardHead space-y-2">
        
          <div className="flex justify-end items-center md:gap-2 text-[30px] text-[#ffd700] px-2 py-2 md:p-3 h-[35px]">
          {
          data?.method === 'private' &&
            <MdWorkspacePremium />
          }
          </div>
       
        <div className="flex justify-between items-center md:gap-2 px-2 py-3 md:p-3">
          <div className="inline-flex items-center gap-2 max-w-[60%]">
            {data?.hospitalId?.profilePic ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${data?.hospitalId?.profilePic}`}
                className="size-10 min-w-10  bg-white rounded-full object-cover"
              />
            ) : (
              // <div className="min-w-10 size-10  bg-gray-300 rounded-full "></div>
              <Avatar name={data?.hospitalId?.hospitalName} size="40" round={true} />
            )}
            <p
              className="subHeadingText !leading-[18px] text-coalBlack capitalize  line-clamp-1"
              title={data?.hospitalId?.hospitalName}
            >
              {data?.hospitalId?.hospitalName}
            </p>
          </div>
          <div className="p-1 inline-block bg-sky-100 text-accent baseText whitespace-nowrap rounded-full capitalize user-select-none min-w-24 text-center">
            {gettypeText()}
          </div>
        </div>

        <div
          className={
            'bg-lightBlue px-2 py-3 md:p-3 rounded-xl flex items-start justify-between min-h-[4.5rem]'
          }
        >
          <p className="text-navlink baseText line-clamp-2 max-w-[80%]">
            {data?.RequestDescription}
          </p>

          <div
            className={`p-1 inline-block baseText whitespace-nowrap  rounded-full capitalize user-select-none min-w-24 text-center ${getConsultationRequestStyle()}`}
          >
            {gettypePriorityText()}
          </div>
        </div>
      </div>
      <div className="cardFooter flex items-center justify-start gap-3 max-w-sm mx-auto">
        <SimpleButton
          title={fillBtnTitle}
          buttonType={'primary'}
          customClass={'rounded-full w-full'}
          onClickEvent={fillBtnfun}
        />
        {unFillBtnTitle == true ? (
          <SimpleButton
            title={data.status}
            buttonType={'primary-unfilled'}
            customClass={'rounded-full text-accent border-accent border'}
          // onClickEvent={() => {
          //  unFillBtnTitle==="Decline" ?  setdeletepopup(true):'';
          // }}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

ConsultationCard.defaultProps = {
  fillBtnTitle: 'View Details',
  unFillBtnTitle: false,
};

export default ConsultationCard;
