import React, { useContext, useEffect, useState } from 'react';
import {
  useWeavy,
  WyChat,
  WyNotificationToasts,
  Weavy,
  WyNotifications,
} from '@weavy/uikit-react';
import { getOddsWeavyTokenApi } from '../../../apis';
import { AppContext } from '../../../appContext';
function WeavyNotification() {
  const { userDetails } = useContext(AppContext);
  // check safari version
  const [browserInfo, setBrowserInfo] = useState('');
  const [isChatAllowed, setIsChatAllowed] = useState(false);

  useEffect(() => {
    const getBrowserInfo = () => {
      const userAgent = navigator.userAgent;
      let browserDetails = 'Browser not detected';
      let allowChat = false;

      if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        // Extract Safari version
        const safariVersionMatch = userAgent.match(/Version\/(\d+\.\d+)/);
        const safariVersion = safariVersionMatch
          ? parseFloat(safariVersionMatch[1])
          : 0;

        if (safariVersion >= 17) {
          browserDetails = `Safari version: ${safariVersion}`;
          allowChat = true;
        } else {
          browserDetails = `Safari version: ${safariVersion} (Chat not allowed for versions below 17)`;
          allowChat = false;
        }
      } else if (userAgent.includes('Chrome')) {
        browserDetails = 'You\'re using Chrome, chat is allowed.';
        allowChat = true;
      } else {
        browserDetails = 'You\'re using another browser, chat is allowed.';
        allowChat = true;
      }

      setBrowserInfo(browserDetails);
      setIsChatAllowed(allowChat);
    };

    getBrowserInfo();
  }, []);

  console.log(browserInfo);

  return (
    <>
      {isChatAllowed ? (
        <WyNotifications></WyNotifications>
      ) : (
        <div className="min-h-[40vh] w-full flex justify-center items-center ">
          <p className="texBase text-center">
             This feature is only available in Safari browser
          </p>
        </div>
      )}
    </>
  );
}

export default WeavyNotification;
