import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import { InputErrorMessage } from '../../components/errorMessages';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import MandatoryLabel from '../../components/common/label';
import toast from 'react-hot-toast';

import {
  ResetPasswordValidation,
  deleteAcctPassword,
} from '../../components/Validation';
import { changePassword, confirmPassword } from '../../apis';
import { EditProfileLoader } from '../../components/common/loader';
import UpdatePassword from './UpdatePassword';
import ProfileTopNavigation from './ProfileTopNavigation';
import { AppContext } from '../../appContext';
function ChangePassword() {
  const {userDetails} = useContext(AppContext);
  const [passwordType, setPasswordType] = useState('password');
  const [updatePasswordBlock, setUpdatePasswordBlock] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: deleteAcctPassword,
    onSubmit: async (values) => {
      console.log(values);
      const { password } = values;
      const payload = {
        userId: userDetails?._id,
        password: password,
      };
      onConfirmPassword(payload);
    },
  });
  const { password } = formik.values;
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }

  const onConfirmPassword = async (payload) => {
    try {
      const res = await confirmPassword(payload);
      if (res.status == 200) {
        toast.success('Set New Password');
        setUpdatePasswordBlock(!updatePasswordBlock);
      } else {
        const message = res.data.error || res.statusText;
        toast.error(message, { id: 'error' });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data?.error);
    }
  };

  const afterPasswordUpdate = () => {
    formik.resetForm();
    setUpdatePasswordBlock(!updatePasswordBlock);
  };

  return (
    <div>
      <div className="inline-flex gap-2 items-center">
        <ProfileTopNavigation pageTitle={'Change password'} />
      </div>

      <form className="mt-12 space-y-4 max-w-md">
        <h2 className="baseText !font-medium max-w-md mb-4">
          Enter your current password associated with this account to reset
          password{' '}
        </h2>
        <div className="relative">
          <MandatoryLabel title="Current Password" />
          <div className="relative">
            <input
              type={passwordType}
              name="password"
              className={
                Boolean(formik.touched.password) &&
                Boolean(formik.errors.password)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="password"
              placeholder="Password "
              value={formik.values.password}
              onChange={handleChange}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik.touched.password && formik.errors.password}
          />
        </div>
        {!updatePasswordBlock && (
          <>
            <SimpleButton
              customClass={'bg-accent text-white rounded-lg w-full'}
              buttonType={'primary'}
              title={'Continue'}
              onClickEvent={formik.handleSubmit}
            />
          </>
        )}
      </form>
      {updatePasswordBlock && (
        <UpdatePassword afterPasswordUpdate={afterPasswordUpdate} />
      )}
    </div>
  );
}

export default ChangePassword;
