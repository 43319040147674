import React, { useEffect, useReducer,useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { FaStarOfLife } from 'react-icons/fa6';
import { InputErrorMessage } from '../../components/errorMessages';
import {
  DoctorRegistrationStepFour,
  DoctorRegistrationStepOne,
  DoctorRegistrationStepThree,
  DoctorRegistrationStepTwo,
} from '../../components/Validation/index.js';
import { useFormik } from 'formik';
import { EditProfileLoader } from '../../components/common/loader.js';
import { useState } from 'react';
import { BiBell } from 'react-icons/bi';
import { FiChevronLeft } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';
import { LuUpload } from 'react-icons/lu';
import { PiCertificateFill } from 'react-icons/pi';
import { BsFileMedicalFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { FaFileAlt } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa6';
import MobileModel from '../../components/Modals/MobileModel.js';
import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import { getProfileById, profileCreate } from '../../apis/index.js';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../components/UserProfile/UserProfile.js';

function RegisterOne({ formik, loader }) {
  const inputRef= useRef(null);
  const openDatePicker = () => {
    if ('showPicker' in inputRef.current && typeof inputRef.current.showPicker === 'function') {
      inputRef.current.showPicker();
    }
  };
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  return (
    <form onSubmit={formik.handleSubmit} className="pb-6">
      <section className="space-y-4 shadow-md rounded-xl p-4 md:shadow-none">
        <h3 className="text-lg capitalize font-medium">Personal Details</h3>
        <div className="docName">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Doctor’s Name
          </p>
          <div className="relative">
            <input
              type="text"
              name="docName"
              className={
                Boolean(formik.touched.docName) &&
                Boolean(formik.errors.docName)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="docName"
              placeholder="Enter doctor name"
              value={formik.values.docName}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.docName && formik.errors.docName}
            />
          </div>
        </div>
        <div className="age">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            DOB
          </p>
          <div className="relative">
            <input
              ref={inputRef}
              type="date"
              name="birthDate"
              className={
                Boolean(formik.touched.birthDate) &&
                Boolean(formik.errors.birthDate)
                  ? 'form-control datePlaceholder border-danger transparent-input-border'
                  : 'form-control datePlaceholder transparent-input-border'
              }
              id="birthDate"
              placeholder="age"
              value={formik.values.birthDate}
              onChange={handleChange}
              onClick={openDatePicker} // Use onClick to trigger the date picker
            />
            <InputErrorMessage
              error={formik.touched.birthDate && formik.errors.birthDate}
            />
          </div>
        </div>
        <div className="gender">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Gender
          </p>
          <div className="relative">
            <div className="flex flex-wrap gap-3">
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={formik.values.gender === 'male'}
                  onChange={handleChange}
                />
                <label htmlFor="male" className="">
                  Male
                </label>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  checked={formik.values.gender === 'female'}
                  onChange={handleChange}
                />
                <label htmlFor="female" className="">
                  Female
                </label>
              </div>
              <div className="flex items-center gap-1">
                <input
                  type="radio"
                  name="gender"
                  id="others"
                  value="others"
                  checked={formik.values.gender === 'others'}
                  onChange={handleChange}
                />
                <label htmlFor="others" className="">
                  Other
                </label>
              </div>
            </div>
            <InputErrorMessage
              error={formik.touched.gender && formik.errors.gender}
            />
          </div>
        </div>
        <div className="phoneNo">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Mobile Number
          </p>
          <div className="relative">
            <div className="flex gap-1 items-center">
              <input
                type="text"
                readOnly
                className="form-control cursor-not-allowed max-w-12 p-2 inline-flex justify-center items-center"
                value={'+91'}
              />
              <input
                type="text"
                name="phoneNo"
                className={
                  Boolean(formik.touched.phoneNo) &&
                  Boolean(formik.errors.phoneNo)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="phoneNo"
                placeholder="Enter 10 digit mobile No"
                value={formik.values.phoneNo}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.phoneNo && formik.errors.phoneNo}
            />
          </div>
        </div>
       
        <div className="submitButton mt-4">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Next'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </section>
    </form>
  );
}

export default RegisterOne;
