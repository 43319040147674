import React, { useContext } from 'react';
import { IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import { AppContext } from '../../appContext';
function UserProfile({ imgUrl, mailId, NotificationBlock }) {
  const { chatCount } = useContext(AppContext);
  let badgeElm;
  if (chatCount > 0) {
    badgeElm = (
      <span className="min-w-[18px] h-[18px]  inline-flex justify-center items-center bg-[#1648CE] text-white !font-semibold smallText rounded-full absolute top-0 right-0">
        {chatCount}
      </span>
    );
  } else {
    // badgeElm = (
    //   <span className="w-2 h-2 bg-[#1648CE] rounded-full absolute top-2 right-2"></span>
    // );
  }
  return (
    <div className="flex justify-between items-center">
      <Link to={'/profile'}>
        <div className="flex items-center gap-2 ">
          <div className="m-w-12 size-12 bg-white rounded-full bg-avatar">
            <img
              src={imgUrl ? `${process.env.REACT_APP_API_URL}/${imgUrl}` : ProfileDefault}
              className="block object-cover rounded-full w-full h-full"
            />
          </div>
          <div>
            <p className="baseText !font-medium text-coalBlack w-full line-clamp-1 overflow-hidden sm:max-w-max flex flex-col">
              {mailId}
            </p>
          </div>
        </div>
      </Link>
      <div className={`relative ${NotificationBlock ? 'block' : 'hidden'}`}>
        <Link to={'/notification'} className="relative block">
          <IoNotifications className="w-9 h-9 text-2xl text-coalBlack hover:bg-blue-50 rounded-full p-1.5 cursor-pointer" />
          {badgeElm}
        </Link>
      </div>
    </div>
  );
}

UserProfile.defaultProps = {
  mailId: '',
  NotificationBlock: false,
};
export default UserProfile;
