import React, { useEffect, useState, useRef, useContext } from 'react';
import AdminLayout from '../../components/admin-layout';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { CgSortAz } from 'react-icons/cg';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import Select from 'react-select';
import TypesenseMasherListComponent from '../../components/TypesenseList/TypesenseMasherListComponent';
import TypesenseConsultationListComponent from '../../components/TypesenseList/TypesenseConsultation';
import LoaderCard from '../../components/ConsultationCard/LoaderCard';
import { getAllConsultation } from '../../apis';
import ViewDetails from '../../components/ConsultationCard/ViewDetails';
import { AppContext } from '../../appContext';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
function Index() {
  const { userDetails } = useContext(AppContext);
  const [tabType, setTabType] = useState('All');
  const sort = ['Newest', 'Oldest'];
  const sortOption = sort.map((el) => {
    const container = {};
    container['value'] = 'test';
    container['label'] = 'test2';

    return container;
  });
  const hiddenButtonRef = useRef(null);
  const [sortOpt, setSortOpt] = useState('pending');
  const [type, settype] = useState('');
  const [filterval, setfilterval] = useState('');
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState(true);
  const [data, setData] = useState(null);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  function handelTabs(event, prop) {
    setTabType(prop);
    if (prop === 'Remote') {
      settype('consType');
      setfilterval('virtual');
    } else if (prop === 'InPerson') {
      settype('consType');
      setfilterval('inperson');
    } else if (prop === 'Routine') {
      settype('priority');
      setfilterval('regular');
    } else if (prop === 'Urgent') {
      settype('priority');
      setfilterval('urgent');
    } else {
      settype('');
      setfilterval('');
    }
    const tabElm = document.querySelectorAll('.tabBtn');
    const removeClass = ['bg-accent', 'text-white'];
    const addClass = ['bg-accent', 'text-white'];
    tabElm.forEach((data) => {
      data.classList.remove(...removeClass);
    });
    event.target.classList.add(...addClass);
  }

  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME_NEW,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-hospitalConsultation',
          sort_by: 'createdAt:asc',
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  function onload() {
    // hiddenButtonRef.current.click();
    // location.reload();
    setReload(!reload);
  }
  const [reloadtext, setreloadtext] = useState('');
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');
  const [locationfilter,setlocationfilter]=useState(0);
  useEffect(() => {
    getLocation(); // Trigger on component mount
  }, []); // The empty array ensures this effect runs only once after the initial rendering

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(success, handleError);
  };
  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });
    setError('');
  };
  const handleError = (error) => {
    switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log('User denied the request for Geolocation.');
      setError('Please enable location services and reload the page.');
      handelLocationPromt();
      break;
    case error.POSITION_UNAVAILABLE:
      setError('Location information is unavailable.');
      break;
    case error.TIMEOUT:
      setError('The request to get user location timed out.');
      break;
    default:
      setError('An unknown error occurred.');
      break;
    }
  };
  const [LocationPromt, setLocationPromt] = useState(false);
  function handelLocationPromt() {
    setLocationPromt(!LocationPromt);
  }
  const toGetAllConsultation = async (id, sortOpt, filterType, filterValue,location,
    lat,
    long) => {
      console.log(location);
      console.log(lat);
      console.log(long);
    try {
      setLoader(true);
      const res = await getAllConsultation(
        id,
        sortOpt,
        filterType,
        filterValue,
        location,
        lat,
        long
      );
      console.log(res);
      if (res.status === 200) {
        setData(res?.data?.AllHospitalConsultation);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    toGetAllConsultation(
      userDetails?.DoctorProfile?._id,
      sortOpt,
      type,
      filterval,
      locationfilter,
      location.latitude,
      location.longitude,

    );
  }, [tabType, reload, sortOpt, userDetails,locationfilter]);
  
  const notFound = (
    <div className="h-72 flex justify-center items-center col-span-3 3xl:col-span-4">
      <p className="baseText text-navLink capitalize">
        No Consultations found.
      </p>
    </div>
  );
  function centerTab(tabId) {
    const tabsContainer = document.getElementById('tabs-container');
    const tab = document.getElementById(tabId);
    if (tab && tabsContainer) {
      const containerWidth = tabsContainer.clientWidth;
      const tabWidth = tab.clientWidth;

      const offset = tab.offsetLeft - containerWidth / 2 + tabWidth / 2;

      tabsContainer.scrollTo({
        left: offset,
        behavior: 'smooth',
      });
    }
  }
  function handelViewRequestBlock(data) {
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata', data?._id);
    setCurrentRequest(data);
  }
  console.log(userDetails);
  
  return (
    <>
      <AdminLayout>
        <section className="pt-4 xl:p-5 xl:pb-0">
          <div className="flex justify-between items-center rounded-lg bg-white p-4 xl:p-5 ">
            <p className=" text-coalBlack headingText capitalize">
              request history
            </p>
          </div>
          <section className="bg-white p-4 rounded-sm mt-1 mb-14 xl:mb-0 xl:mt-14 xl:p-6">
            <div
              className="bg-neutral-100 rounded-lg p-2 flex items-center gap-1 overflow-x-auto "
              id="tabs-container"
            >
              <button
                id="tab1"
                className={
                  'tabBtn px-4 py-2 text-coalBlack baseText rounded-lg capitalize whitespace-nowrap bg-accent text-white'
                }
                onClick={(e) => {
                  handelTabs(event, 'All');

                  centerTab('tab1');
                }}
              >
                All
              </button>
              <button
                id="tab2"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap'
                }
                onClick={(e) => {
                  handelTabs(event, 'Remote');

                  centerTab('tab2');
                }}
              >
                remote
              </button>
              <button
                id="tab3"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap'
                }
                onClick={(e) => {
                  handelTabs(event, 'InPerson');
                  centerTab('tab3');
                }}
              >
                in-person
              </button>
              <button
                id="tab4"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap '
                }
                onClick={(e) => {
                  handelTabs(event, 'Urgent');

                  centerTab('tab4');
                }}
              >
                urgent
              </button>
              <button
                id="tab5"
                className={
                  'tabBtn px-4 py-2  text-coalBlack baseText rounded-lg capitalize whitespace-nowrap }'
                }
                onClick={(e) => {
                  handelTabs(event, 'Routine');
                  centerTab('tab5');
                }}
              >
                Routine
              </button>
            </div>
            <div className="mt-6 flex justify-between items-center ">
              <div className="inline-flex gap-4 items-center">
                <p className="baseText text-coalBlack capitalize">
                  {tabType} Consultations
                </p>
              </div>
              <div className='flex gap-3 flex-row'>
                {
                  !error &&
                
              <div className="inline-flex items-center cursor-pointer relative group/sort">
                <CgSortAz className="text-3xl text-accent font-light" />
                <p className="text-coalBlack baseText !font-medium">Distance</p>
                <div className="absolute z-50 top-full -left-half bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[145px] invisible group-hover/sort:visible">
                 <p
                    className={`${
                      locationfilter === 0
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1 `}
                    onClick={() => {
                      setlocationfilter(0);
                    }}
                  >
                    All
                  </p>
                  <p
                    className={`${
                      locationfilter === 2
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1 `}
                    onClick={() => {
                      setlocationfilter(2);
                    }}
                  >
                    Nearby 2km
                  </p>
                  <p
                    className={`${
                      locationfilter === 5
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1`}
                    onClick={() => {
                      setlocationfilter(5);
                    }}
                  >
                    Upto 5km
                  </p>
                  <p
                    className={`${
                      locationfilter === 10
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1`}
                    onClick={() => {
                      setlocationfilter(10);
                    }}
                  >
                    Upto 10km
                  </p>
                </div>
              </div>
              }
              <div className="inline-flex items-center cursor-pointer relative group/sort">
                <CgSortAz className="text-3xl text-accent font-light" />
                <p className="text-coalBlack baseText !font-medium">Sort</p>
                <div className="absolute z-50 top-full -left-full bg-white rounded-md shadow-md inline-flex flex-col p-1 gap-1 w-[125px] invisible group-hover/sort:visible">
                  <p
                    className={`${
                      sortOpt == 'completed'
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1 `}
                    onClick={() => {
                      setSortOpt('completed');
                    }}
                  >
                    Completed
                  </p>
                  <p
                    className={`${
                      sortOpt == 'pending'
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1`}
                    onClick={() => {
                      setSortOpt('pending');
                    }}
                  >
                    Pending
                  </p>
                  <p
                    className={`${
                      sortOpt == 'no-show'
                        ? 'text-white bg-accent hover:!bg-accent'
                        : ''
                    } hover:bg-slate-50 baseText px-2 rounded-md py-1`}
                    onClick={() => {
                      setSortOpt('no-show');
                    }}
                  >
                    No Show
                  </p>
                </div>
              </div>
              </div>
             
            </div>
            <div className="mt-6  grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4">
              
              {loader ? (
                <>
                  <LoaderCard />
                  <LoaderCard />
                  <LoaderCard />
                </>
              ) : data?.length != 0 ? (
                data?.map((item, key) => (
                  <ConsultationCard
                    data={item}
                    onload={onload}
                    fillBtnfun={() => handelViewRequestBlock(item)}
                  />
                ))
              ) : (
                notFound
              )}
            </div>
          </section>
        </section>
        {ViewRequestBlock && (
          <ViewDetails
            closeFunction={handelViewRequestBlock}
            currentdata={currentRequest?._id}
            // profileData={profileData}
          />
        )}
         {/* Locaiton Promt pop up  */}
         <div
            className={`fixed top-0 left-0 p-4 w-full h-full bg-black/25  justify-center items-center z-40 ${
              LocationPromt ? 'flex' : 'hidden'
            }`}
          >
            <div className="bg-white px-4 py-8 rounded-3xl shadow-sm space-y-8 w-full max-w-sm md:px-6 md:py-6 md:space-y-10">
              <p className="text-center text-coalBlack baseText">
                Enable location services to get the distance to your designated
                hospital from your current location.
              </p>
              <div className="grid grid-cols-1">
                <SimpleButton
                  title={'Close'}
                  buttonType={'primaryUnfilled'}
                  customClass={'rounded-xl'}
                  onClickEvent={handelLocationPromt}
                />
              </div>
            </div>
          </div>
      </AdminLayout>
    </>
  );
}

export default Index;
