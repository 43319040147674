import React, { useContext, useEffect, useState } from 'react';
import { useWeavy, WyChat, Weavy } from '@weavy/uikit-react';
import { getOddsWeavyTokenApi } from '../../../apis';
import { AppContext } from '../../../appContext';
import { EditProfileLoader, Loader } from '../../common/loader';
import './weavyStyle..css';

function OddsChat({ data, consultationId }) {
  const [hospitalToken, setHospitalToken] = useState(null);
  const [doctorToken, setDoctorToken] = useState(null);
  const { userDetails } = useContext(AppContext);

  const getWeavyToken = async (user) => {
    try {
      const res = await getOddsWeavyTokenApi(user);
      if (res.status === 200 || res.status === 201) {
        return res.data.token;
      }
    } catch (err) {
      console.error('Error:', err);
      throw err;
    }
  };

  useEffect(() => {
    const initializeChat = async () => {
      try {
        const hospitalDetails = {
          userId: userDetails?.DoctorProfile?._id,
          username: `Dr.${userDetails?.DoctorProfile?.DoctorName}`,
        };
        const doctorDetails = {
          userId: consultationId,
          username: data?.hospitalName.replace(/\s+/g, ''),
        };

        const isHospitalToken = await getWeavyToken(hospitalDetails);
        const isDoctorToken = await getWeavyToken(doctorDetails);

        setHospitalToken(isHospitalToken);
        setDoctorToken(isDoctorToken);
        localStorage.setItem('hospitalChatToken', isHospitalToken);
        localStorage.setItem('doctorChatToken', isDoctorToken);
        // Initialize Weavy instance
        const weavy = new Weavy();
        weavy.notificationEvents = true;
        weavy.url = process.env.REACT_APP_WEAVY_URL;
        weavy.tokenFactory = async () =>
          localStorage.getItem('hospitalChatToken');

        // Clean up Weavy instance on unmount
        return () => {
          console.log('Cleaning up Weavy instance');
          weavy.destroy();
        };
      } catch (err) {
        console.error('Error initializing chat:', err);
      }
    };

    initializeChat();
  }, [userDetails, consultationId, data]);
  return (
    <>
      {hospitalToken && doctorToken ? (
        <>
          <WyChat
            uid={consultationId}
            className="h-dvh max-h-dvh pt-16 overscroll-contain lg:h-[96dvh]"
            name={'chat'}
            autoCapitalize="true"
            autoFocus="true"
          ></WyChat>
        </>
      ) : (
        <div className="min-h-[100vh] w-full flex justify-center items-center ">
          <Loader />
        </div>
      )}
    </>
  );
}

export default OddsChat;
