import React, { useEffect, useState, useRef, useContext } from "react";
import { getWithdrawHistory } from "../../apis";
import PaginatedTable from "../../components/TableOdds/PaginatedTable";
import { AppContext, WalletContext } from "../../appContext";
import { BsCurrencyRupee } from "react-icons/bs";

import moment from "moment";

const WithdrawHistory = () => {
  const [handelWithdrawBlock, doctorProfile, setUpdatePage, updatePage] =
    useContext(WalletContext);

  const { userDetails } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [mobileData, setMobileData] = useState([]);
  const tableHeaders = [
    {
      requestDate: "Request date",
      withdrawDate: "Withdraw date",
      amount: "Amount",
      status: "Status",
      balance: "Balance",
    },
  ];
  const toGetWithdrawHistory = async (id) => {
    try {
      const res = await getWithdrawHistory(id);
      if (res.status === 200) {
        const array = [];
        res?.data?.WithdrawHistory?.map((data) => {
          const obj = {
            name: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {moment(data?.createdAt).format("DD-MM-YYYY")}
              </p>
            ),
            appointment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize text-center">
                {data?.status == "Paid"
                  ? moment(data?.paidDate).format("DD-MM-YYYY")
                  : "--"}
              </p>
            ),
            sales: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.withdrawAmt}
              </p>
            ),
            payment: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.status == "Requested" ? "Pending" : data?.status}
              </p>
            ),
            status: (
              <p className="whitespace-nowrap line-clamp-1 baseText text-coalBlack capitalize">
                {data?.remainingSavingAmt}
              </p>
            ),
          };
          array.push(obj);
        });
        setData(array?.reverse());
        setMobileData(res?.data?.WithdrawHistory);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    toGetWithdrawHistory(userDetails?.DoctorProfile?._id);
  }, [userDetails, updatePage]);

console.log("mobile data",mobileData)
  return loader ? (
    <div className="cardLoader h-48 lg:h-96"></div>
  ) : (
    <>
      <h1 className="text-coalBlack headingText ps-2.5 py-2 border-b border-gray-400 mb-2">
        Withdraw History
      </h1>
      {
        (mobileData?.length > 0 && mobileData) ? 
        <>
         <div className="hidden md:block">
        <PaginatedTable
          items={data}
          itemsPerPage={10}
          tableHeaders={tableHeaders}
        />
      </div>
      <div className="md:hidden flex flex-col gap-3 py-2">
        {mobileData?.map((withdraw) => (
           <div className="consultationCard bg-blue-100 shadow-card_shadow px-3 py-4 rounded-2xl md:px-3 gap-2 flex justify-between border border-[#1648ce80]">
           <div className=" flex flex-col">
             <span className="text-slate-500 capitalize line-clamp-1 subHeadingText font-bold">
               Request date
             </span>
             <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
               Withdraw date
             </span>
             <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
               Amount
             </span>
             <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
               Balance
             </span>
             <span className="text-slate-500 capitalize line-clamp-1 subHeadingText">
               Status
             </span>
           </div>
           <div className=" flex flex-col items-end">
             <span className="subHeadingText text-coalBlack capitalize line-clamp-1 flex items-center font-bold mr-1">
             {moment(withdraw?.createdAt).format("DD-MM-YYYY")}
             </span>
             <span className="subHeadingText text-coalBlack capitalize line-clamp-1 flex items-center font-bold mr-1">
             {withdraw?.status == "Paid"
                  ? moment(withdraw?.paidDate).format("DD-MM-YYYY")
                  : "--"}
             </span>
             <span className="subHeadingText text-coalBlack capitalize line-clamp-1 flex items-center font-bold mr-1">
               <span>
                 <BsCurrencyRupee />
               </span>
               <span>{withdraw?.withdrawAmt}</span>
             </span>
             <span className="subHeadingText text-coalBlack capitalize line-clamp-1 flex items-center font-bold mr-1">
               <span>
                 <BsCurrencyRupee />
               </span>
               <span>{withdraw?.remainingSavingAmt}</span>
             </span>
             <div
               className={`px-3 py-1 inline-block baseText rounded-full capitalize user-select-none text-center whitespace-nowrap ${
                withdraw?.status == "Requested" ? "bg-red-200 text-red-500" : "bg-green-400 text-green-200"
               }`}
             >
               {withdraw?.status == "Requested" ? "Pending" : withdraw?.status}
             </div>
           </div>
         </div>
        ))}
      </div>
        </>
        :
        <>
        <p className="baseText text-navLink text-center lg:col-span-2 capitalize  py-10 baseText">
        No withdrawals yet.
          </p>
        </>
      }
     
    </>
  );
};

export default WithdrawHistory;
