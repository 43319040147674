import React, { useState, useContext } from "react";
import ProfileTopNavigation from "./ProfileTopNavigation";

function PrivacyPolicy() {
  function HeadingText({ text }) {
    return (
      <div className="mt-4 mb-2 baseText !font-medium max-w-xs">{text}</div>
    );
  }
  function HeadingTextLight({ text }) {
    return (
      <strong><div className="mt-4 mb-2 baseText font-medium max-w-xs text-gray-500">{text}</div></strong>
    );
  }

  function Paragraph({ text }) {
    return <p className="baseText text-navLink mb-2">{text}</p>;
  }
  const LableValueCon=({lable,value})=>{
    return(
      <div className="flex flex-row gap-1">
         <strong><p className="baseText font-medium max-w-xs text-gray-500">{lable}: </p></strong>
         <p className="baseText text-navLink">{value} </p>
      </div>
    )
  }
  function BulletList({ items }) {
    return (
      <ul className="list-disc pl-5 baseText text-navLink">
        {items.map((item, index) => (
          <li key={index}><strong><p className="baseText text-navLink">{item.value}</p></strong></li>
        ))}
      </ul>
    );
  }
  const items = [
    { value: "Facilitate the connection between hospitals and doctors. Manage appointment scheduling and consultations." },
    { value: "Process payments and invoices." },
    { value: "Enhance the platform through analytics and marketing initiatives." }
  ];
  
  return (
    <div className="text-coalBlack pb-[25%]">
      <ProfileTopNavigation pageTitle={"Privacy and Safety"} />
      <section className="my-8 mx-2">
        <HeadingText text={"Data Collection"} />
        <HeadingTextLight text={"We collect the following personal and professional information:"} />
        <Paragraph
          text={`Name, address, email ID, mobile number, medical license, and other professional credentials.`}
        />
        <HeadingText text={"Use of Data"} />
        <HeadingTextLight text={"The data collected is used to:"} />
        <BulletList 
        items={items}
        />
        <HeadingText text={"Data Sharing"} />
        <Paragraph
          text={` User data may be shared with trusted third-party services for analytics, marketing,and platform improvement.`}
        />  
        <Paragraph text={'Data will not be sold to unauthorized third parties.'} />
        <HeadingText text={"Data Storage and Security"} />
        <Paragraph
          text={` All sensitive data, including user credentials and payment information, is stored securely on AWS servers.`}
        />  
        <Paragraph text={' MODO implements industry-standard security measures to protect against unauthorized access, data breaches, and other vulnerabilities.'} />
        <HeadingText text={"User Rights"} />
        <Paragraph
          text={`Users can access, update, or request deletion of their personal data by contacting MODO support. Requests are subject to legal or regulatory obligations MODO must fulfill.`}
        />  
         <HeadingText text={"Cookies and Tracking"} />
        <Paragraph
          text={`MODO uses cookies and tracking technologies to enhance user experience, monitor platform performance, and gather analytics for marketing purposes.`}
        />  
         <HeadingText text={"Dispute Resolution"} />
        <Paragraph
          text={`For any disputes related to data handling, users can contact MODO support.`}
        />  
         <HeadingText text={"Contact Us"} />
        <HeadingTextLight
          text={`For questions regarding this Privacy Policy, reach out to:`}
        /> 
        <LableValueCon 
        lable={'Email'}
        value={'modobackend@gmail.com'}
        />

        
      </section>
    </div>
  );
}

export default PrivacyPolicy;
