import React, { useContext, useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { IoVideocam } from 'react-icons/io5';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { FaSquareCheck } from 'react-icons/fa6';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { ConsultationAcceptApi, getSingleRequest } from '../../apis';
import { FaUserDoctor } from 'react-icons/fa6';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../common/loader';
import toast from 'react-hot-toast';
import { formatDate } from '../Helperfunction/timeconverter';
import { CgCalendarDates } from 'react-icons/cg';
import MapComponent from '../MapComponent';
import { CiLocationArrow1 } from 'react-icons/ci';
import { BsCurrencyRupee } from 'react-icons/bs';
function calculateDiscountedPrice(originalPrice, discountPercentage) {
  if (originalPrice <= 0 || discountPercentage < 0) {
    return 'Invalid input';
  }

  const discountAmount = (originalPrice * discountPercentage) / 100;
  const finalPrice = originalPrice - discountAmount;

  return finalPrice;
}

function ViewRequest({ closeFunction, reqId, getAllRecentRequest, onload }) {
  const [cardLoader, setCardLoader] = useState(true);
  const [conformPopUp, setConformPopUp] = useState(false);
  const [LocationPromt, setLocationPromt] = useState(false);
  const [data, setData] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const [checkStatus, setCheckStatus] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');
  useEffect(() => {
    getLocation(); // Trigger on component mount
  }, []); // The empty array ensures this effect runs only once after the initial rendering

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(success, handleError);
  };

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });
    setError('');
  };
  function haversineDistance(lat1, lon1, lat2, lon2) {
    const toRadians = (degree) => (degree * Math.PI) / 180;

    const R = 6371; // Earth's radius in kilometers
    const deltaLat = toRadians(lat2 - lat1);
    const deltaLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(deltaLon / 2) *
        Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    const formateddistance = formatDistance(distance);
    return formateddistance;
  }
  const formatDistance = (distance) => {
    if (distance < 1) {
      return `${(distance * 1000).toFixed(0)} meters`;
    } else {
      return `${distance.toFixed(2)} km`;
    }
  };
  const handleError = (error) => {
    switch (error.code) {
    case error.PERMISSION_DENIED:
      console.log('User denied the request for Geolocation.');
      setError('Please enable location services and reload the page.');
      handelLocationPromt();
      break;
    case error.POSITION_UNAVAILABLE:
      setError('Location information is unavailable.');
      break;
    case error.TIMEOUT:
      setError('The request to get user location timed out.');
      break;
    default:
      setError('An unknown error occurred.');
      break;
    }
  };

  const onGetSingleRequest = async (id) => {
    try {
      const res = await getSingleRequest(id);
      if (res.status === 200) {
        console.log(res.data.consultationdata);
        setData(res?.data?.consultationdata);
        setTransactionData(res.data?.transactionData);
        setCheckStatus(res.data.consultationdata.Consultationstatus);
        setCardLoader(!cardLoader);
      }
    } catch (error) {
      setCardLoader(true);
      console.log(error);
    }
  };
  function gettypeText() {
    const text = {
      inperson: 'In-Person',
      Virtual: 'Virtual',
    };
    return text[data?.consType];
  }
  function getPriorityStyle() {
    console.log(data?.priority);
    const style = {
      urgent: 'bg-[#FF4539] text-white',
      Routine: 'bg-green-200 text-green-500',
      regular: 'bg-green-200 text-green-500',
    };
    return style[data?.priority];
  }

  useEffect(() => {
    onGetSingleRequest(reqId);
  }, []);

  function handelConfirmPopUp() {
    setConformPopUp(!conformPopUp);
  }
  function handelLocationPromt() {
    setLocationPromt(!LocationPromt);
  }
  const [loader, setLoader] = useState(false);
  const { userDetails } = useContext(AppContext);
  const requestAccept = async () => {
    try {
      setLoader(true);
      const res = await ConsultationAcceptApi({
        consultationId: data?._id,
        doctorId: userDetails?.DoctorProfile?._id,
        status: 'accepted',
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        setConformPopUp(!conformPopUp);
        closeFunction();
        onload();
        // location.reload();

        // console.log('request accepted');
      }
    } catch (error) {
      console.log(error?.response?.data?.message);

      setLoader(false);
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
    }
  };

  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      {/* card loader screen  */}
      {cardLoader ? (
        <div className=" fixed top-0 w-full h-screen flex justify-center items-center">
          <div className="bg-gray-200 z-30  w-full h-full p-4  m-auto lg:max-w-lg lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl">
            <div className="space-y-6 m-auto">
              <div className="cardLoader w-full h-14 rounded-lg"></div>
              <div className="cardLoader w-full h-24 rounded-lg"></div>
              <div className="cardLoader w-full h-24 rounded-lg"></div>
              <div className="cardLoader w-full h-24 rounded-lg"></div>
              <div className="cardLoader w-full h-32 rounded-lg"></div>
            </div>
          </div>
        </div>
      ) : checkStatus === 'pending' ? (
        <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-lg lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl overscroll-contain">
          {/* LG Navigation  */}
          <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
            <p className="text-coalBlack subHeadingText">
              Consultation Request
            </p>
            <p
              className="text-navLink smallText !font-medium min-w-10 p-2 cursor-pointer"
              onClick={closeFunction}
            >
              Close
            </p>
          </div>
          {/* LG profile viewer  */}
          <div className="profileForLargeDevice rounded-3xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
            {/* <div>
              {data?.hospitalcoverPhoto ? (
                <img
                  src={data?.hospitalcoverPhoto}
                  className="bg-white h-36 rounded-2xl w-full object-cover"
                />
              ) : (
                <div className="bg-gray-100 h-36 rounded-2xl w-full "></div>
              )}
              {data?.hospitalProfilePhoto ? (
                <img
                  src={data?.hospitalProfilePhoto}
                  className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white"
                />
              ) : (
                <div className="w-14 h-14 bg-gray-400 rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white"></div>
              )}
            </div> */}
            <div>
              {data?.hospitalId?.profilePic ? (
                <img
                  className="h-36 rounded-2xl bg-white w-[100%] object-cover aspect-square"
                  src={data?.hospitalId?.profilePic}
                />
              ) : (
                <div
                  className="bg-gray-100 dark:bg-gray-700 h-36 rounded-2xl "
                  role="status"
                >
                  <p className="subHeadingText text-center capitalize py-6">
                    {' '}
                    {data?.hospitalId?.hospitalName}
                  </p>
                </div>
              )}
              {data?.hospitalId?.profilePic ? (
                <img
                  className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white object-cover"
                  src={data?.hospitalId?.profilePic}
                />
              ) : (
                <div
                  role="status"
                  className="bg-gray-50 dark:bg-gray-700 w-14 h-14  rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white"
                >
                  <p className="subHeadingText text-center capitalize w-full h-full flex justify-center items-center">
                    {' '}
                    {data?.hospitalId?.hospitalName}
                  </p>
                </div>
              )}
            </div>
            <div className="px-6 space-y-3">
              <p className="text-coalBlack text-center headingText  capitalize">
                {data?.hospitalId?.hospitalName}
              </p>
              <div className="flex items-start gap-2">
                <FaLocationDot className="text-xl text-accent min-w-4" />
                <p className="baseText text-gray-400">
                  {data?.hospitalId?.address}
                </p>
              </div>
              <div className="flex items-start gap-2">
                <IoMdCall className="text-xl text-accent min-w-4" />
                <p className="baseText text-gray-400">
                  {data?.hospitalId?.refPhoneNo}
                </p>
              </div>
            </div>
          </div>
          <main className="bg-white p-4 pt-0 pb-12 space-y-3.5  lg:rounded-2xl lg:py-4 min-h-screen">
            {/* SM Navigation  */}
            <div className=" bg-white flex items-center gap-1 sticky top-0 py-4 z-30 lg:hidden">
              <FiChevronLeft
                className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
                onClick={closeFunction}
              />
              <p className="subHeadingText  text-coalBlack capitalize">Back</p>
            </div>
            {/* SM profile viewer  start*/}
            <div className="profileForSmallDevice flex items-center gap-2 my-2 p-2  rounded-2xl shadow-blue_dropshadow lg:hidden">
              {data?.hospitalProfilePhoto ? (
                <img
                  src={data?.hospitalProfilePhoto}
                  className="min-w-10 size-10 object-cover  bg-white rounded-full"
                />
              ) : (
                <div className="min-w-10 h-10 bg-blue-400 rounded-full"></div>
              )}

              <p className="headingText text-coalBlack max-w-[70%] line-clamp-2 capitalize">
                {data?.hospitalId?.hospitalName}
              </p>
              <p
                className={`smallText px-4 py-1.5 rounded-full ms-auto capitalize ${getPriorityStyle()}`}
              >
                {data?.priority}
              </p>
            </div>
            <div className="baseText text-coalBlack bg-lightBlue p-3 rounded-lg space-y-3 lg:hidden">
              <div className="flex gap-2 items-start">
                <FaLocationDot className="text-xl text-accent min-w-4" />
                <p className="baseText"> {data?.hospitalId?.address}</p>
              </div>
              <div className="flex gap-2 items-start">
                <IoMdCall className="text-xl text-accent min-w-4" />
                <p className="baseText"> {data?.hospitalId?.refPhoneNo}</p>
              </div>
            </div>
            {/* SM profile viewer  end*/}
            {/* view details start */}
            <section className="baseText space-y-6 pb-4">
              <div className="space-y-2 requestDescription">
                <p className="subHeadingText text-coalBlack ">
                  Request Description
                </p>
                <p className="baseText text-gray-500">
                  {data?.RequestDescription}
                </p>
              </div>
              <div className="consultationType space-y-2 lg:hidden">
                <div className="flex items-center gap-1">
                  <p className="baseText text-coalBlack">Consultation Type</p>
                  <div className="bg-accent w-4 h-4 flex justify-center items-center rounded-full text-white text-[8px]">
                    i
                  </div>
                </div>
                <div className="inline-flex items-center gap-3 px-3  pe-10 py-2 rounded-lg bg-lightBlue">
                  {data?.consType == 'inperson' ? (
                    <div className="min-w-10 h-10 rounded-lg bg-green-100 flex justify-center items-center">
                      <FaUserDoctor className="text-2xl text-green-700" />
                    </div>
                  ) : (
                    <div className="min-w-10 h-10 rounded-lg bg-purple-100 flex justify-center items-center">
                      <IoVideocam className="text-2xl text-purple-700" />
                    </div>
                  )}
                  <p className="text-sm font-medium text-coalBlack capitalize">
                    {gettypeText()}
                  </p>
                </div>
              </div>

              <div className="consultationType space-y-2 lg:hidden">
                <div className="flex items-center gap-1">
                  <p className="baseText text-coalBlack">Requested Date</p>
                </div>
                <div className="inline-flex items-center gap-3 px-3  pe-10 py-2 rounded-lg bg-lightBlue">
                  <div className="min-w-10 h-10 rounded-lg bg-green-100 flex justify-center items-center">
                    <CgCalendarDates className="text-accent text-xl" />
                  </div>
                  <p className="text-sm font-medium text-coalBlack capitalize">
                    {formatDate(data?.RequestedDate)}
                  </p>
                </div>
              </div>
              {/* lg devices  */}
              <div className="grid-cols-2 gap-x-2 gap-y-4 consultationTypeLg hidden lg:grid">
                <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                  <p className="!font-medium baseText  text-navLink px-3 py-1">
                    Consultation Type
                  </p>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                    {data?.consType == 'inperson' ? (
                      <div className="min-w-10 h-10 rounded-lg bg-green-100 flex justify-center items-center">
                        <FaUserDoctor className="text-2xl text-green-700" />
                      </div>
                    ) : (
                      <div className="min-w-10 h-10 rounded-lg bg-purple-100 flex justify-center items-center">
                        <IoVideocam className="text-2xl text-purple-700" />
                      </div>
                    )}
                    <p className="baseText text-coalBlack capitalize">
                      {gettypeText()}
                    </p>
                  </div>
                </div>
                <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                  <p className="!font-medium baseText text-navLink px-3 py-1">
                    Request Priority
                  </p>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                    <div className="min-w-4 h-8 rounded-lg  flex justify-center items-center">
                      <FaSquareCheck
                        className={`text-lg ${
                          data?.priority == 'urgent'
                            ? 'text-[#FF637B]'
                            : 'text-green-500'
                        }`}
                      />
                    </div>
                    <p className="baseText text-coalBlack capitalize">
                      {data?.priority}
                    </p>
                  </div>
                </div>
                <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                  <p className="!font-medium baseText text-navLink px-3 py-1">
                    Requested Date
                  </p>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                    <div className="min-w-4 h-8 rounded-lg  flex justify-center items-center">
                      <CgCalendarDates className="text-accent text-xl" />
                    </div>
                    <p className="baseText text-coalBlack capitalize">
                      {formatDate(data?.RequestedDate)}
                    </p>
                  </div>
                </div>
                {/* <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                  <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                    Reference Doctor
                  </p>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                    <div className="min-w-8 h-8 rounded-full bg-gray-200"></div>
                    <p className="text-sm font-medium text-coalBlack max-w-36 truncate whitespace-nowrap text-ellipsis">
                      {requestData?.referralDoctor}
                    </p>
                  </div>
                </div>
                <div className="bg-white shadow-blue_dropshadow rounded-2xl">
                  <p className="font-medium text-base text-[#A3AED0] px-3 py-1">
                    Contact Doctor
                  </p>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-lg ">
                    <IoMdCall className="text-accent text-xl" />
                    <p className="text-sm font-medium text-coalBlack">
                      Request A call with <br /> Doctor
                    </p>
                  </div>
                </div> */}
              </div>

              {/* <div className="referenceDoctor space-y-2 lg:hidden">
                <p className="text-lg font-medium text-coalBlack xl:text-xl">
                  Reference Doctor
                </p>
                <div className="grid grid-cols-1  gap-2 md:grid-cols-2">
                  <div className="bg-lightBlue px-2 py-5 rounded-xl">
                    <div className="flex items-start gap-2">
                      <div className="min-w-10 h-10 rounded-full bg-gray-200"></div>
                      <div>
                        <p className="text-coalBlack font-medium text-sm max-w-[24ch] truncate text-ellipsis">
                          {requestData?.referralDoctor}
                        </p>
                        <p className="text-coalBlack font-light text-sm max-w-[24ch] truncate text-ellipsis">
                          M.B.B.S general
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-lightBlue px-2 py-5 rounded-xl">
                    <div className="flex items-center gap-2">
                      <IoMdCall className="text-accent text-2xl min-w-10" />
                      <div>
                        <p className="text-coalBlack font-medium text-sm">
                          Request A call <br /> with Doctor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="MedicalDetail space-y-2">
                <p className="subHeadingText text-coalBlack ">Medical Detail</p>
                <div className="flex flex-wrap gap-3 items-center">
                  {data?.medicalTag?.map((data, i) => (
                    <div
                      className="px-4 py-2 min-w-20 text-center rounded-full text-coalBlack baseText bg-gray-100"
                      key={i}
                    >
                      {data}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="flex justify-between">
                  <h1 className="subHeadingText">Earnings</h1>
                  <span className="flex items-center subHeadingText">
                    <BsCurrencyRupee /> {transactionData?.doctor_fee}
                  </span>
                </div>
                {/* <div className="flex justify-between">
                  <span>Specialization</span>
                  <span className="flex items-center">
                    <BsCurrencyRupee />{' '}
                    {calculateDiscountedPrice(
                      transactionData?.fee_details?.specalaization_fee,
                      transactionData?.fee_details?.commission_percentage
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Consultation Type</span>
                  <span className="flex items-center">
                    <BsCurrencyRupee />{' '}
                    {calculateDiscountedPrice(
                      transactionData?.fee_details?.consultation_type_fee,
                      transactionData?.fee_details?.commission_percentage
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Total</span>
                  <span className="flex items-center">
                    <BsCurrencyRupee /> {transactionData?.doctor_fee}
                  </span>
                </div> */}
              </div>
              {data?.hospitalId?.lat != 0 && data?.hospitalId?.log != 0 ? (
                <>
                  <div className="HospitalLocation bg-white shadow-card_shadow p-4 rounded-2xl">
                    <div className="flex justify-between items-center flex-row my-5">
                      <p className="subHeadingText text-coalBlack ">
                        Hospital Location
                      </p>
                      {!error && (
                        <p className="baseText text-gray-500 flex justify-between items-center flex-row gap-1">
                          <CiLocationArrow1 />{' '}
                          {haversineDistance(
                            location?.latitude,
                            location.longitude,
                            data?.hospitalId?.lat,
                            data?.hospitalId?.long
                          )}
                        </p>
                      )}
                    </div>

                    {/* <div className="mt-2 rounded-[10px] min-h-40 bg-gray-100"></div> */}
                    {data?.hospitalId?.lat ? (
                      <MapComponent
                        latitude={data?.hospitalId?.lat}
                        longitude={data?.hospitalId?.long}
                      />
                    ) : (
                      <MapComponent latitude={0} longitude={0} />
                    )}
                  </div>
                </>
              ) : null}
            </section>
            {/* view details end */}
          </main>
          {/* Accept btn  */}
          <div className="bg-bodybackground my-2 px-2 py-2  sticky bottom-16 lg:-bottom-4 lg:px-0 z-40">
            <div className="bg-white p-2 rounded-2xl xl:p-4">
              <div className="flex items-center justify-evenly gap-4">
                <SimpleButton
                  title={'Accept'}
                  buttonType={'primary'}
                  customClass={'rounded-xl'}
                  onClickEvent={handelConfirmPopUp}
                />
                <SimpleButton
                  title={'Close'}
                  buttonType={'primaryUnfilled'}
                  customClass={'rounded-xl'}
                  onClickEvent={closeFunction}
                />
              </div>
            </div>
          </div>
          {/* conformation pop up  */}
          <div
            className={`fixed top-0 left-0 p-4 w-full h-full bg-black/25  justify-center items-center z-40 ${
              conformPopUp ? 'flex' : 'hidden'
            }`}
          >
            <div className="bg-white px-4 py-8 rounded-3xl shadow-sm space-y-4 w-full max-w-sm md:px-6 md:py-6 md:space-y-2">
              <div className="flex justify-center gap-x-2">
                <h1 className="subHeadingText">Earnings</h1>
                <span className="flex items-center subHeadingText">
                  <BsCurrencyRupee /> {transactionData?.doctor_fee}
                </span>
              </div>
              <div className="text-center text-coalBlack baseText flex flex-col gap-1">
                <p>Are you sure you want to accept this request?</p>
                <span className="text-center text-red-400 baseText smallText">
                  Once accepted, this action cannot be undone, and you won't be
                  able to delete or remove it.
                </span>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <SimpleButton
                  title={loader ? <EditProfileLoader /> : 'Yes'}
                  buttonType={'primary'}
                  customClass={'rounded-xl'}
                  onClickEvent={requestAccept}
                />
                <SimpleButton
                  title={'Cancel'}
                  buttonType={'primaryUnfilled'}
                  customClass={'rounded-xl'}
                  onClickEvent={handelConfirmPopUp}
                />
              </div>
            </div>
          </div>
          {/* Locaiton Promt pop up  */}
          <div
            className={`fixed top-0 left-0 p-4 w-full h-full bg-black/25  justify-center items-center z-40 ${
              LocationPromt ? 'flex' : 'hidden'
            }`}
          >
            <div className="bg-white px-4 py-8 rounded-3xl shadow-sm space-y-8 w-full max-w-sm md:px-6 md:py-6 md:space-y-10">
              <p className="text-center text-coalBlack baseText">
                Enable location services to get the distance to your designated
                hospital from your current location.
              </p>
              <div className="grid grid-cols-1">
                <SimpleButton
                  title={'Close'}
                  buttonType={'primaryUnfilled'}
                  customClass={'rounded-xl'}
                  onClickEvent={handelLocationPromt}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-lg lg:py-4 lg:px-8 lg:max-h-[96vh] h-full lg:rounded-3xl">
          {/* SM Navigation  */}
          <div className=" bg-white flex items-center gap-1 sticky top-0 py-4 z-30 lg:hidden">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={closeFunction}
            />
            <p className="text-sm font-normal text-coalBlack capitalize">
              Back
            </p>
          </div>
          {/* LG Navigation  */}
          <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
            <p className="text-coalBlack text-lg font-medium">
              Consultation Request
            </p>
            <p
              className="text-gray-500 text-sm font-medium min-w-10 p-2 cursor-pointer"
              onClick={closeFunction}
            >
              Close
            </p>
          </div>
          <div className="flex justify-center items-center h-40">
            <p className="text-center text-coalBlack font-normal">
              Sorry, This request not available at the moment.{' '}
            </p>
          </div>
        </div>
      )}
    </section>
  );
}

export default ViewRequest;
