import React, { useContext, useEffect, useState } from 'react';
import ConsultationCard from '../../components/ConsultationCard/ConsultationCard';
import { BsFillCalendarDateFill } from 'react-icons/bs';
// import Dropdown from '../../components/common/dropdown/Dropdown';
import ProfileTopNavigation from './ProfileTopNavigation';
import {
  getAppointmentHistory,
  getDoctorApibyUserID,
  getUserApi,
} from '../../apis';
import { ProfileSliderContext } from '../../appContext';

function AppointmentHistory() {
  const [appointmentHistoryData, setAppointmentHistoryData] = useState(null);
  const [slider, setSlider, handelViewRequestBlock] = useContext(
    ProfileSliderContext
  );
  const [filterHistory, setFilterHistory] = useState('yearly'); //weekly  monthly  yearly
  const [doctorProfileId, setDoctorProfileId] = useState(null);
  const toGetAppointmentHistory = async (id) => {
    try {
      const res = await getAppointmentHistory(id);
      console.log(res);
      if (res.status === 200) {
        console.log(res.data.AppointmentHistory);
        setAppointmentHistoryData(res.data?.AppointmentHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log('profile id', res?.data?.Profile?._id);
        setDoctorProfileId(res?.data?.Profile?.DoctorProfile?._id);
        toGetAppointmentHistory(res?.data?.Profile?.DoctorProfile?._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleHistoryFilter = (event) => {
    const value = event.target.value;
    toGetAppointmentHistory(`${doctorProfileId}&filter=${value}`);
    setFilterHistory(value);
  };
  return (
    <>
      <div className="flex items-center flex-wrap gap-4 mb-5 lg:mb-0">
        <ProfileTopNavigation pageTitle={'Appointment History'} />
        {/* <p className="text-sm capitalize">
          <BsFillCalendarDateFill className="text-accent text-2xl me-1 inline-block" />
          
        </p> */}
        <select
          name="report"
          className=" bg-accent text-white px-4 py-2 rounded-[4px]  capitalize ms-auto baseText w-full lg:w-auto"
          onChange={handleHistoryFilter}
          value={filterHistory}
        >
          <option
            className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
            value={'weekly'}
          >
            {' '}
            Weekly report
          </option>
          <option
            className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
            value={'monthly'}
          >
            Monthly report
          </option>
          <option
            className="text-coalBlack bg-white font-medium text-sm whitespace-nowrap"
            value={'yearly'}
          >
            Yearly report
          </option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4 mt-8 pb-[25%]">
        {appointmentHistoryData?.length != 0 ? (
          appointmentHistoryData?.map((data, key) => (
            <div key={key}>
              <ConsultationCard
                fillBtnTitle={'View Detail'}
                data={data}
                ViewRequest={() => {
                  handelViewRequestBlock(data);
                }}
                fillBtnfun={() => {
                  handelViewRequestBlock(data);
                }}
              />
            </div>
          ))
        ) : (
          <p className="text-center lg:col-span-2 capitalize baseText text-navLink py-10 baseText">
            No consultation found
          </p>
        )}
      </div>
    </>
  );
}

export default AppointmentHistory;
